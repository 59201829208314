import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CreateFileName {

  constructor() {
  }


  createFileName(name: string, date: string): string {
    const lastIndex = name.lastIndexOf('.');
    const fileName = lastIndex !== -1 ? name.substring(0, lastIndex) : name;
    const fullName = fileName + date + '.jpg';
    return fullName;
  }

}
