<div class="row mt-3" *ngIf="!isUpdateDealer">
  <!-- create form -->
  <div class="col-4" *ngIf="isCreateDealer">
    <div class="card p-3">
      <div class="card-body">
        <form [formGroup]="createDealerForm">
          <div class="mb-3">
            <label class="form-label">Login Name</label>
            <input type="text" class="form-control" formControlName="loginName">
          </div>
          <div class="mb-3">
            <label class="form-label">Password</label>
            <input type="password" class="form-control" formControlName="password">
          </div>
          <div class="mb-3">
            <label class="form-label">Verify Phone</label>
            <input type="tel" class="form-control" formControlName="verifyPhone">
          </div>
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" formControlName="enabled">
            <label class="form-check-label">Enabled</label>
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="card-footer text-right">
        <button class="btn btn-light mr-3" routerLink="/nav/dealer">Close</button>
        <button type="button" class="btn btn-primary" (click)="createDealer()" [disabled]="!createDealerForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Next</span>
        </button>
      </div>
    </div>
  </div>

  <!-- register form -->
  <div class="col-8" *ngIf="!isCreateDealer">
    <div class="card p-3 ml-5">
      <div class="card-body">
        <form [formGroup]="registerDealerForm">

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">SAP Dealer ID *</label>
              <input type="text" class="form-control" formControlName="sapDealerId">
            </div>
            <!-- <div class="col">
              <label class="form-label">Dealer Type</label>
              <select class="form-select" formControlName="dealerType">
                <option *ngFor="let type of dealerTypes" [value]="type">{{ type }}</option>
              </select>
            </div> -->
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Contact Person Name *</label>
              <input type="text" class="form-control" formControlName="contactPersonName">
            </div>
            <div class="col">
              <label class="form-label">Email</label>
              <input type="email" class="form-control" formControlName="email" placeholder="a@gmail.com">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Title</label>
              <input type="text" class="form-control" formControlName="title">
            </div>
            <div class="col">
              <label class="form-label">NRC</label>
              <input type="text" class="form-control" formControlName="nrc">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Shop Company Name *</label>
              <input type="text" class="form-control" formControlName="shopCompanyName">
            </div>
            <div class="col">
              <label class="form-label">Company Register No</label>
              <input type="text" class="form-control" formControlName="companyRegisterNo">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Contact Phone</label>
              <input type="tel" class="form-control" formControlName="contactPhone">
            </div>
            <div class="col">
              <label class="form-label">Viber Phone *</label>
              <input type="tel" class="form-control" formControlName="viberPhone">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Address Line 1</label>
              <input type="text" class="form-control" formControlName="addressLine1">
            </div>
            <div class="col">
              <label class="form-label">Address Line 2</label>
              <input type="text" class="form-control" formControlName="addressLine2">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Address Line 3</label>
              <input type="text" class="form-control" formControlName="addressLine3">
            </div>
            <div class="col">
              <label class="form-label">Address Line 4</label>
              <input type="text" class="form-control" formControlName="addressLine4">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">City Code</label>
              <input type="number" class="form-control" formControlName="cityCode">
            </div>
            <div class="col">
              <label class="form-label">City Name</label>
              <input type="text" class="form-control" formControlName="cityName">
            </div>
            <div class="col">
              <label class="form-label">Sales District Code</label>
              <input type="number" class="form-control" formControlName="salesDistrictCode">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Township</label>
              <input type="text" class="form-control" formControlName="township">
            </div>
            <div class="col">
              <label class="form-label">Region</label>
              <input type="text" class="form-control" formControlName="region">
            </div>
            <div class="col">
              <label class="form-label">Division State</label>
              <input type="text" class="form-control" formControlName="divisionState">
            </div>
          </div>

        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="card-footer text-right">
        <button class="btn btn-light mr-3" routerLink="/nav/dealer">Close</button>
        <button type="button" class="btn btn-primary" (click)="registerDealer()" [disabled]="!registerDealerForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- update form -->
<div class="row mt-3" *ngIf="isUpdateDealer">
  <div class="col">
    <div class="card p-3">
      <div class="card-body">
        <form [formGroup]="updateDealerForm">

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Email</label>
              <input type="email" class="form-control" formControlName="email" placeholder="a@gmail.com">
            </div>
            <div class="col">
              <label class="form-label">NRC</label>
              <input type="text" class="form-control" formControlName="nrc">
            </div>
            <div class="col">
              <label class="form-label">Title</label>
              <input type="text" class="form-control" formControlName="title">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Contact Person Name *</label>
              <input type="text" class="form-control" formControlName="contactPersonName">
            </div>
            <div class="col">
              <label class="form-label">Contact Phone</label>
              <input type="tel" class="form-control" formControlName="contactPhone">
            </div>
            <div class="col">
              <label class="form-label">Viber Phone *</label>
              <input type="tel" class="form-control" formControlName="viberPhone">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">City Code</label>
              <input type="number" class="form-control" formControlName="cityCode">
            </div>
            <div class="col">
              <label class="form-label">City Name</label>
              <input type="text" class="form-control" formControlName="cityName">
            </div>
            <div class="col">
              <label class="form-label">Sales District Code</label>
              <input type="text" class="form-control" formControlName="salesDistrictCode">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Township</label>
              <input type="text" class="form-control" formControlName="township">
            </div>
            <div class="col">
              <label class="form-label">Region</label>
              <input type="text" class="form-control" formControlName="region">
            </div>
            <div class="col">
              <label class="form-label">Division State</label>
              <input type="text" class="form-control" formControlName="divisionState">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Shop Company Name *</label>
              <input type="text" class="form-control" formControlName="shopCompanyName">
            </div>
            <div class="col">
              <label class="form-label">Company Register No</label>
              <input type="text" class="form-control" formControlName="companyRegisterNo">
            </div>
            <!-- <div class="col">
              <label class="form-label">Verify Phone *</label>
              <input type="tel" class="form-control" formControlName="verifyPhone">
            </div> -->
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Address Line 1</label>
              <input type="text" class="form-control" formControlName="addressLine1">
            </div>
            <div class="col">
              <label class="form-label">Address Line 2</label>
              <input type="text" class="form-control" formControlName="addressLine2">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label class="form-label">Address Line 3</label>
              <input type="text" class="form-control" formControlName="addressLine3">
            </div>
            <div class="col">
              <label class="form-label">Address Line 4</label>
              <input type="text" class="form-control" formControlName="addressLine4">
            </div>
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <!-- <div class="col text-left align-self-center ml-3">
            <input type="checkbox" class="form-check-input" [checked]="isEnabled">
            <label class="form-check-label">Enabled</label>
          </div> -->
          <div class="col text-right">
            <button class="btn btn-light mr-3" (click)="backDealerInfo()">Back</button>
            <button type="button" class="btn btn-primary" (click)="updateInfo()" [disabled]="!updateDealerForm.valid">
              <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="!isUploading">Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
