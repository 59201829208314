import { DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import '@angular/localize/init';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { authInterceptorProviders } from './app/utils/auth.interceptor';
import { DateFormatPipe } from './app/utils/date-format.pipe';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatterService } from './app/utils/custom-date-formatter.service';

  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule
      ),
      provideRouter(routes),
      provideHttpClient(withInterceptorsFromDi()),
      authInterceptorProviders,
      DatePipe,
      DateFormatPipe,
      { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
    ],
  }).catch(err => console.error(err));
