<div class="d-flex">
  <div class="w-auto mr-3" *ngIf="showBalance">
    <h5>
      Total Unpaid Balance : {{totalUnpaidBalance === 'Total invoice balance is zero' ? '0' :
      totalUnpaidBalance?.toLocaleString()}} Ks
    </h5>
  </div>
  <div class="w-auto mr-auto">
    <h5 *ngIf="showBalance">
      Total Due Balance : {{ totalDueBalance === 'Total invoice balance is zero' ? '0' :
      totalDueBalance?.toLocaleString() }} Ks
    </h5>
  </div>
  <div class="d-flex pl-3">
    <div class="w-auto form-control fs-6" *ngIf="filterName">{{filterName}}</div>
    <div>
      <button type="button" class="btn btn-primary ml-1" data-bs-toggle="modal" data-bs-target="#filterModal">
        <i class="fas fa-filter"></i>
      </button>
    </div>
    <div>
      <button type="button" class="btn btn-danger ml-1" (click)="clearFilter()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="pl-3">
    <input type="text" class="form-control" (keyup)='searchByIDList(first.value)' #first="ngModel" ngModel
      placeholder="Search by Invoice Number">
    <!-- <div class="input-group">
      <input type="text" class="form-control" placeholder="Invoice Number" [ngbTypeahead]="searchList"
        [(ngModel)]="searchID">
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" (click)="searchByID()" [disabled]="!searchID">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-danger" type="button" (click)="clearSearchByID()" [disabled]="!searchID">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div> -->
  </div>
  <div class="pl-3" *ngIf="isDownloadAccess">
    <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#downloadCSVModal">Download CSV
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Sr No</th>
        <th>Invoice Number</th>
        <th>Invoice Date</th>
        <th>Invoice Amount</th>
        <th>Due Date</th>
        <th>Paid Amount</th>
        <th>Paid Date</th>
        <th>Balance Amount</th>
        <th>Payment Method</th>
        <th>Ship to Party</th>
        <th>Sold to CSD</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of invoiceList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1 ,
          totalItems: allTotal
        }" (click)="invoiceInfo(data.referenceId)">
        <td>{{data.srNo}}</td>
        <td><a class="link-primary" (click)="goToInvoicePDF(data.referenceId)"
            style="cursor: pointer;">{{data.referenceId}}</a></td>
        <!-- <td>{{data.referenceId}}</td> -->
        <td>{{data.documentDate}}</td>
        <td>{{data.invoiceAmount}}</td>
        <td>{{data.dueDate}}</td>
        <td>{{data.paidAmount}}</td>
        <td>{{data.paidDate}}</td>
        <td>{{data.balanceAmount}}</td>
        <td>{{data.paymentMethod}}</td>
        <td>{{data.shipToParty}}</td>
        <td>{{data.soldToCsd}}</td>
      </tr>
      <tr *ngIf="currentPageTotal && invoiceType === 'UNPAID' && !showPageTotal">
        <!-- <tr *ngIf="false"> -->
        <td scope="col" colspan="3" class="text-center">Current Page Total</td>
        <td scope="col" colspan="2">{{currentPageTotal.invoiceTotal}}</td>
        <td scope="col" colspan="2">{{currentPageTotal.paidTotal}}</td>
        <td scope="col" colspan="4">{{currentPageTotal.balanceTotal}}</td>
      </tr>
      <tr *ngIf="previousPageTotal && invoiceType === 'UNPAID' && !showPageTotal">
        <!-- <tr *ngIf="false"> -->
        <td scope="col" colspan="3" class="text-center">Previous Page Total</td>
        <td scope="col" colspan="2">{{previousPageTotal.invoiceTotal}}</td>
        <td scope="col" colspan="2">{{previousPageTotal.paidTotal}}</td>
        <td scope="col" colspan="4">{{previousPageTotal.balanceTotal}}</td>
      </tr>
      <tr *ngIf="aggregatedTotal && invoiceType === 'UNPAID' && !showPageTotal">
        <!-- <tr *ngIf="false"> -->
        <td scope="col" colspan="3" class="text-center">Aggregated Page Total</td>
        <td scope="col" colspan="2">{{aggregatedTotal.invoiceTotal}}</td>
        <td scope="col" colspan="2">{{aggregatedTotal.paidTotal}}</td>
        <td scope="col" colspan="4">{{aggregatedTotal.balanceTotal}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please Enter File Name</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()" [disabled]="!downloadFileName">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Filter Model -->
<div class="modal" id="filterModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Select</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label class="form-label">Invoice Type *</label>
          <select class="form-select" [(ngModel)]="invoiceType">
            <option *ngFor="let type of invoiceTypeList" [value]="type">{{ type }}</option>
          </select>
        </div>
        <div class="mb-3" *ngIf="!isDealer">
          <label class="form-label">Dealer Login Name *</label>
          <input type="text" class="form-control" [(ngModel)]="searchName" [ngbTypeahead]="searchList">
        </div>
        <!-- <div class="mb-3" *ngIf="invoiceType !== 'DUE_TODAY'">
          <label class="form-label">Order By</label>
          <select class="form-select" [(ngModel)]="orderBy">
            <ng-container *ngIf="invoiceType === 'ALL' || invoiceType === 'UNPAID'; else paid">
              <option *ngFor="let type of orderByDuDate" [value]="type">{{ type }}</option>
            </ng-container>
            <ng-template #paid>
              <option *ngFor="let type of orderByPaidDate" [value]="type">{{ type }}</option>
            </ng-template>
          </select>
        </div> -->

        <div class="mb-3" *ngIf="invoiceType !== 'DUE_TODAY'">
          <label class="form-label">Start Date</label>
          <!-- <input type="date" [(ngModel)]="startDate" class="form-control" /> -->
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="startDate" placeholder="DD/MM/YYYY" ngbDatepicker
              #dstart="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="dstart.toggle()" type="button">
                <i class="bi bi-calendar3"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="mb-3" *ngIf="invoiceType !== 'DUE_TODAY'">
          <label class="form-label">End Date</label>
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="endDate" placeholder="DD/MM/YYYY" ngbDatepicker
              #dend="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="dend.toggle()" type="button">
                <i class="bi bi-calendar3"></i>
              </button>
            </div>
          </div>
          <!-- <div class="input-group date">
            <input type="date" [(ngModel)]="endDate" class="form-control" />
          </div> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="!checkValid()" (click)="filterInvoiceList(0)">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
