import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class RouteLinkByRoles {

  super_admin_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    { icon: 'fa fa-user', link: '/nav/admin', name: 'Admin' },
    { icon: 'fa fa-solid fa-users', link: '/nav/dealer', name: 'Dealer' },
    // { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-box-archive', link: '/nav/brand', name: 'Brand' },
    { icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    { icon: 'fa fa-solid fa-star', link: '/nav/premier', name: 'Premier Point' },
    { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    { icon: 'fa fa-solid fa-calendar', link: '/nav/quarter', name: 'Quarter' },
    { icon: 'fa fa-solid fa-chart-column', link: '/nav/purchasingList', name: 'Purchasing' },
    { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];

  admin_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    //{ icon: 'fa fa-user', link: '/nav/admin', name: 'Admin' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-solid fa-users', link: '/nav/dealer', name: 'Dealer' },
    { icon: 'fa fa-box-archive', link: '/nav/brand', name: 'Brand' },
    { icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    //{ icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    //{ icon: 'fa fa-solid fa-calendar', link: '/nav/quarter', name: 'Quarter' },
    //{ icon: 'fa fa-solid fa-chart-column', link: '/nav/purchasingList', name: 'Purchasing' },
    { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];

  dealer_admin_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    //{ icon: 'fa fa-user', link: '/nav/admin', name: 'Admin' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-solid fa-users', link: '/nav/dealer', name: 'Dealer' },
    // { icon: 'fa fa-box-archive', link: '/nav/brand', name: 'Brand' },
    // { ico: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    // { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    // { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    // { icon: 'fa fa-solid fa-calendar', link: '/nav/quarter', name: 'Quarter' },
    // { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];


  mdo_admin_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    //{ icon: 'fa fa-user', link: '/nav/admin', name: 'Admin' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-solid fa-users', link: '/nav/dealer', name: 'Dealer' },
    { icon: 'fa fa-box-archive', link: '/nav/brand', name: 'Brand' },
    { icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    { icon: 'fa fa-solid fa-star', link: '/nav/premier', name: 'Premier Point' },
    // { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    { icon: 'fa fa-solid fa-calendar', link: '/nav/quarter', name: 'Quarter' },
    //{ icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-solid fa-chart-column', link: '/nav/purchasingList', name: 'Purchasing' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];

  fid_admin_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    //{ icon: 'fa fa-user', link: '/nav/admin', name: 'Admin' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-solid fa-users', link: '/nav/dealer', name: 'Dealer' },
    //{ icon: 'fa fa-box-archive', link: '/nav/brand', name: 'Brand' },
    //{ icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    // { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    // { icon: 'fa fa-solid fa-chart-column', link: '/nav/quarter', name: 'Quarter' },
    // { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];

  price_list_admin_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    //{ icon: 'fa fa-user', link: '/nav/admin', name: 'Admin' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    //{ icon: 'fa fa-solid fa-users', link: '/nav/dealer', name: 'Dealer' },
    //{ icon: 'fa fa-box-archive', link: '/nav/brand', name: 'Brand' },
    //{ icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    // { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    //{ icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    // { icon: 'fa fa-solid fa-chart-column', link: '/nav/quarter', name: 'Quarter' },
    // { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];

  dealer_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-solid fa-chart-column', link: '/nav/purchasingChartDealer', name: 'Purchasing' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];

  dealer_premier_links: any = [
    { icon: 'fa fa-home', link: '/nav/home', name: 'Home' },
    { icon: 'fa fa-user', link: '/nav/profile', name: 'Profile' },
    { icon: 'fa fa-solid fa-file', link: '/nav/sale', name: 'Sale System' },
    { icon: 'fa fa-solid fa-percent', link: '/nav/rebate', name: 'Rebate' },
    { icon: 'fa fa-solid fa-star', link: '/nav/premier', name: 'Premier Point' },
    { icon: 'fa fa-regular fa-credit-card', link: '/nav/creditNotes', name: 'Credit Notes' },
    { icon: 'fa fa-light fa-file-invoice-dollar', link: '/nav/invoice', name: 'Invoice' },
    { icon: 'fa fa-solid fa-chart-column', link: '/nav/purchasingChartDealer', name: 'Purchasing' },
    { icon: 'fa fa-light fa-money-check-dollar', link: '/nav/priceListPdf', name: 'Price List' },
  ];
}
