import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-faq',
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  faqs: any = []

  faqsList:any =[
    {
         "id": 1,
         "ques": "၁။ UPG Dealer Application အသုံးပြုပုံနှင့်ရည်ရွယ်ချက်",
         "answer": "UPG Dealer Application ဆိုသည်မှာ အရောင်းကိုယ်စားလှယ်ဆိုင်များနှင့် UPG ကုမ္ပဏီတို့ကြား Digital အချိတ်အဆက်ရှိမည့် ဝန်ဆောင်မှုဖြစ်ပါသည်။ ပထမဦးဆုံး Version တွင် လူကြီးမင်းအနေဖြင့် မိမိဆိုင်၏ Invoice Listများ၊ ငွေပေးချေမှုရှင်းတမ်းများ၊ ဈေးနှုန်းများ၊ ကုမ္ပဏီ၏အရောင်းစနစ်များကို UPG Dealer Application မှတစ်ဆင့် Up to Date ကြည့်ရှုနိုင်မည်ဖြစ်ပါသည်။"
    },
    {
         "id": 2,
         "ques": "၂။ UPG Dealer Application ကိုမည်သူတွေပါဝင်ခွင့်ရှိပါသလဲ။",
         "answer": "UPG ကုန်ပစ္စည်းရောင်းချနေသည့် UPG Dealer ဆိုင်များ နှင့် ကုမ္ပဏီနှင့် ငွေအပေးအယူတိုက်ရိုက်ရှိသည့် အရောင်းဆိုင်များပါဝင်ခွင့်ရှိပါသည်။"
    },
    {
         "id": 3,
         "ques": "၃။ UPG Dealer Application ကို ဘယ်က ရယူနိုင်ပါသလဲ။",
         "answer": "UPG Dealer Application ကို Andriod ဖုန်းများအတွက် Play Store မှ Download ရယူခြင်း နှင့် Computer Browser https://dealer.upgportal.com ပေါ်ကနေလည်း ဝင်ရောက်အသုံးပြုနိုင်မည်ဖြစ်သည်။"
    },
    {
         "id": 4,
         "ques": "၄။ UPG Dealer Application အား အသုံးပြုခြင်းအတွက် အင်တာနက် လိုအပ်ပါသလား။",
         "answer": "UPG Dealer Application နှင့် Website တို့အား အသုံးပြုခြင်းအတွက် အင်တာနက် မဖြစ်မနေလိုအပ်ပါသည်။"
    },
    {
         "id": 5,
         "ques": "၅။ အကယ်၍ အကောင့်တစ်ခုကိုတစ်ချိန်တည်း စက်ပစ္စည်း Device နှစ်ခုတွင်အသုံးပြုလို့ရပါသလား။",
         "answer": "အကောင့်တစ်ခုကိုတစ်ချိန်တည်း စက်ပစ္စည်း Device နှစ်ခုတွင်အသုံးပြု၍မရနိုင်ပါ။"
    },
    {
         "id": 6,
         "ques": "၆။ မိမိငွေလွှဲပို့ခြင်းအချက်အလက် Update ကို မည်မျှကြာလျှင် UPG Dealer Application တွင် မြင်တွေ့ရပါမည်နည်း။",
         "answer": "လက်ရှိ Version တွင် လူကြီးမင်းငွေလွှဲမှုအား ယခင်အတိုင်း UPG FID အဖွဲ့သို့အကြောင်းကြားရမည်ဖြစ်ပြီး ၎င်းတို့မှ စာရင်းသွင်းပြီးနောက်တစ်ရက်တွင် UPG Dealer Application အတွင်းရှိ လူကြီးမင်း၏ Balance list တွင် ပြန်လည် စစ်ဆေးကြည့်ရှုနိုင်ပါသည်။"
    },
    {
         "id": 7,
         "ques": "၇။ UPG Dealer Application အကောင့်သည် မည်သည့် အတိုင်းအတာအထိ လုံခြုံစိတ်ချရမှုရှိပါသလဲ။",
         "answer": "UPG Dealer Application အကောင့်တစ်ခုခြင်းစီတိုင်းတွင် စိတ်ချရသော စကားဝှက် ရှိပါသည်။ အဆိုပါ စကားဝှက် ကို အသုံးပြုသူ သာလျှင် သိရှိနိုင်မှာဖြစ်ပါသည်။ ယင်းစကားဝှက်မှာ မဖြစ်မနေ ထားရှိရမှာ ဖြစ်ပါသည်။ စကားဝှက်မေ့ခဲ့ပါက ကုမ္ပဏီ သို့ဆက်သွယ်ပြီး စကားဝှက်အသစ်တစ်ခုရယူရပါမည်။"
    },
    {
         "id": 8,
         "ques": "၈။ UPG ထုတ်ကုန်အရောင်များကို UPG Dealer Application မှ ကြည့်ရှုလို့ရပါသလား။",
         "answer": "UPG ထုတ်ကုန်အရောင်များကို ကြည့်ရှုလိုလျှင် UPG Color Bank application မှ တဆင့် အရောင်များကိုလေ့လာကြည့်ရှုနိုင်ပါသည်။"
    },
    {
         "id": 9,
         "ques": "၉။ UPG Dealer Application အကြောင်းအသေးစိတ်သိရှိလိုပါလျှင်မည်သို့ဆက်သွယ်ရပါသနည်း။",
         "answer": "UPG Dealer Application အသုံးပြုပုံအကြောင်းကို Video ဖြင့် ရှင်းပြထားပါသည်။ ထို့နောက်လူကြီးမင်းတို့ UPG Dealer Application ကို အသေးစိတ်သိရှိလိုပါက info@upgpaint.com (သို့) UPG Facebook Page (သို့) ၀၉၇၅၆၃၃၉၆၆၃ သို့တိုက်ရိုက်ဆက်သွယ်စုံစမ်းမေးမြန်းနိုင်ပါသည်။"
    },
    {
         "id": 10,
         "ques": "၁၀။ စာရင်းမှားယွင်းမှုတွေ့ရှိလျှင် မည်သို့လုပ်ဆောင်ရမည်နည်း။",
         "answer": "စာရင်းမှားယွင်းမှုများရှိပါက UPG ငွေစာရင်းဌာနသို့ ဆက်သွယ်၍မေးမြန်းနိုင်ပါသည်။"
    },
    {
         "id": 11,
         "ques": "၁၁။ Invoice List စာရင်းများကို Excel Format ဖြင့် ထုတ်ယူလို့ရပါသလား။",
         "answer": "UPG Dealer Application ကို Computer ဖြင့်အသုံးပြုပါက Invoice List စာရင်းများကို Excel Format ဖြင့် လူကြီးမင်းတို့ဘက်မှအလွယ်တကူ ဒေါင်းလုတ်ရယူနိုင်ပါသည်။"
    }
]

  constructor(
    private http: HttpClient,
    private router: Router) { }

  ngOnInit(): void {
    const headers = {
      'Content-Type': '*',
      'Accept': '*'
    }
    this.http.get<any>('assets/list/faqs.json', { headers }).subscribe({
      next: data => {
        this.faqs = data
      },
      error: error => {
        alert(error.status + error.message)
        this.router.navigate(['/home'])
      }
    })
  }
}
