import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { LoginApiService } from 'src/app/services/login_service';
import { LogoutApiService } from 'src/app/services/logout_service.';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  apptitle: any = 'UPG Dealer';
  version: any = environment.version
  isLoading: any = false;
  isLoginError: any;
  isOtpError: any;
  isUpdatePasswordError: any;
  txtLoginError: any;
  login_user: any;
  showPassword = false;
  displayStyle: string = 'none';
  otpCode: any;
  loginName: string = ''
  changePasswordDisplayStyle = 'none'
  showNewPassword: any = false
  showConfirmPassword: boolean = false
  isUploading: boolean = false

  loginform = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', Validators.required),
  });

  changePasswordForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', Validators.required),
    confirmPassword: new UntypedFormControl('', Validators.required),
  });


  constructor(
    private service: LoginApiService,
    private logOutService: LogoutApiService,
    private router: Router,
    private storeData: StoreData,
    private refresh: Refrsh) {

  }
  submitAdminLogin() {

    this.isLoading = true;
    var username = this.loginform.get('username')?.value.toLowerCase();
    var password = this.loginform.get('password')?.value;
    var authorization = 'Basic ' + window.btoa(username + ':' + password);

    this.service.getLogin(authorization).subscribe({
      next: (response) => {
        this.storeData.setLoginName(username)
        this.storeData.setAuth(authorization)
        this.storeData.setRole(String(response.userLoginRoleSet))
        this.storeData.setRebateType(String(response.rebateTypeSet))

        if (response.passwordChanged) {
          this.openUpdatePasswordPopup()
        } else {
          this.goToNav()
        }
      },
      error: (e) => {
        this.isLoading = false;
        this.isLoginError = true;
        this.txtLoginError = String(e);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  goToNav() {
    this.router.navigate(['/nav']);
  }

  openPopup() {
    this.loginName = this.loginform.get('username')?.value.toLowerCase()
    if (this.loginName) {
      this.getOTPCode(this.loginName)
      this.isLoginError = false
    } else {
      this.isLoginError = true
      this.txtLoginError = "Please fill User Name"
    }
  }

  openUpdatePasswordPopup() {
    this.changePasswordDisplayStyle = 'block';
  }

  closePopup() {
    this.isOtpError = false
    this.isUpdatePasswordError = false
    this.txtLoginError = ''
    this.displayStyle = 'none';
    this.changePasswordDisplayStyle = 'none'
  }

  getOTPCode(name: string) {
    this.service.getOtpCode(name).subscribe({
      next: () => {
        this.displayStyle = 'block';
      },
      error: (e) => {
        this.isLoginError = true
        this.txtLoginError = e
      },
      complete: () => {
        this.isLoginError = false
      }
    })
  }

  submit() {
    this.isUploading = true
    this.service.getNewPassword(this.loginName, this.otpCode).subscribe({
      next: () => {
        console.log('Success');
        this.closePopup()
      },
      error: (e) => {
        this.isOtpError = true
        this.txtLoginError = e
        this.isUploading = false
      },
      complete: () => {
        this.isLoginError = false
        this.txtLoginError = ''
        this.isUploading = false
      }
    })
  }

  submitNewPassword() {
    this.isUploading = true
    const newPass = this.changePasswordForm.get('newPassword')?.value
    const confirmPass = this.changePasswordForm.get('confirmPassword')?.value
    if (newPass !== confirmPass) {
      this.isUpdatePasswordError = true
      this.txtLoginError = 'Password does not match'
      this.isUploading = false
    } else {
      this.isUpdatePasswordError = false
      this.updatePassword(confirmPass)
    }
  }

  updatePassword(newPassword: any) {
    this, this.logOutService.getUpdatePassword(newPassword).subscribe({
      next: (value) => {
        const loginName = this.storeData.getLoginName()
        var authorization = 'Basic ' + window.btoa(loginName + ':' + newPassword);
        this.storeData.setAuth(authorization)

        alert("Success")
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isUpdatePasswordError = true
          this.txtLoginError = e
        }
      },
      complete: () => {
        this.closePopup()
        this.isUpdatePasswordError = false
        this.txtLoginError = ''
        this.isUploading = false
        this.goToNav()
      },
    })
  }
}

