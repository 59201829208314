import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { RecordBreakingApiService } from 'src/app/services/record_breaking_service';
import { Refrsh } from 'src/app/utils/refresh';

@Component({
  selector: 'app-purchasing-power-amount-info',
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './purchasing-power-amount-info.component.html',
  styleUrls: ['./purchasing-power-amount-info.component.css']
})
export class PurchasingPowerAmountInfoComponent {
  parseFloat(arg0: any) {
    throw new Error('Method not implemented.');
  }

  dataList: any = []
  nameList: any = []
  quarterName: any
  loginName: any;
  isError: any = false;
  errorMessage: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private refresh: Refrsh,
    private service: RecordBreakingApiService
  ) {

  }

  ngOnInit(): void {
    this.quarterName = this.route.snapshot.paramMap.get('quarterName')
    this.getNameList()
  }

  getNameList() {
    this.service.getNameList().subscribe({
      next: (response) => {
        this.nameList = response
      },
      error: (e) => {
        if (e) {
          alert(e)
        }

      },
      complete: () => {

      },
    })
  }

  searchList = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 1 ? []
        : this.nameList.filter((item: string) => item.toLowerCase().startsWith(term.toLowerCase()))
      )
    );
  }

  searchByName() {
    this.service.getPurchaseInfo(this.loginName).subscribe({
      next: (response) => {
        this.dataList = response
      },
      error: (e) => {
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isError = false
        this.errorMessage = ''
      },
    })
  }

  clearData() {
    this.dataList = []
    this.loginName = ''
  }

  setValue(value: any): String {
    var formattedValue = parseFloat(value).toLocaleString();
    return formattedValue
  }
}
