import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SessionKeys } from './enumeration/sessionKeys';
import { StoreData } from './utils/store_data';

@Component({
  selector: 'app-root',
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'UPG_Dealer_Portal_R2';

  constructor(
    private router: Router,
    private store: StoreData) {

    //this.router.navigate(['/login'])
  }

  ngOnInit(): void {

    if (!this.store.getAuth()) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/nav']);
    }

    window.addEventListener('beforeunload', (event) => {
      sessionStorage.removeItem(SessionKeys.CREDIT_NOTE);
    });
  }
}
