import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StoreData } from '../utils/store_data';
import { TokenRefresh } from './tokenRefresh_service';

@Injectable({
  providedIn: 'root'
})
export class SaleSystemApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh) { }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getSaleSystemList(page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/salessystem/registration/listsalessysteminfo?page=' + page + '&pageSize=' + pageSize, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getCreateSaleSystem(file: any, name: any, des: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    //const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/salessystem/registration/upload?salesSystemName=' + name + '&salesSystemDescription=' + des, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getUpdateSaleSystem(name: string, des: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/salessystem/registration/update?salesSystemName=' + name + '&salesSystemDescription=' + des, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getUpdateSaleSystemFile(name: string, file: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/salessystem/registration/updatefile?salesSystemName=' + name, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getRemoveSaleSystem(name: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/salessystem/registration/remove?salesSystemName=' + name, null, httpOptions)

  }

  //For Dealer
  getSaleSystemName(): Observable<any> {

    //const httpOptions = this.getHeaders()
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/dealer/registration/salessystemname?loginName=' + this.store.getLoginName(), { headers, withCredentials: true })

  }

  getSaleSystemInfo(name: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/salessystem/registration/salessysteminfo?salesSystemName=' + name, httpOptions)

  }

  getDownloadFile(name: string): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/salessystem/registration/downloadsalessystem?salesSystemName=' + name, { headers, withCredentials: true, responseType: 'blob' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
