import { UserLoginRole } from '../../enumeration/roles';
import { StoreData } from 'src/app/utils/store_data';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LogoutApiService } from 'src/app/services/logout_service.';
import { Refrsh } from 'src/app/utils/refresh';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouteLinkByRoles } from 'src/app/utils/route_by_role';
import { RebateType } from 'src/app/enumeration/rebateType';
import { CommonModule } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-nav',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {

  sliderActive = false;
  changePasswordForm: FormGroup<any>;
  isError: any = false
  errorMessage: any;
  link: any
  showNewPassword: any = false
  showConfirmPassword: boolean = false
  isUploading: boolean = false
  loginName: any = ''

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: LogoutApiService,
    private store: StoreData,
    private refresh: Refrsh,
    private links: RouteLinkByRoles) {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    const role = this.store.getRole()

    if (role === UserLoginRole.SUPER_ADMIN) {
      this.link = this.links.super_admin_links
    } else if (role === UserLoginRole.ADMIN) {
      this.link = this.links.admin_links
    } else if (role === UserLoginRole.DEALER_ADMIN) {
      this.link = this.links.dealer_admin_links
    } else if (role === UserLoginRole.MDOFFICE_UPLOAD_ADMIN) {
      this.link = this.links.mdo_admin_links
    } else if (role === UserLoginRole.FINANCE_UPLOAD_ADMIN) {
      this.link = this.links.fid_admin_links
    } else if (role === UserLoginRole.PRICELIST_UPLOAD_ADMIN) {
      this.link = this.links.price_list_admin_links
    } else if (role === UserLoginRole.DEALER) {
      if (this.store.getRebateType().includes(RebateType.PREMIER_POINT)) {
        this.link = this.links.dealer_premier_links
      } else {
        this.link = this.links.dealer_links
      }
      this.store.setIsDealer(true)
    }

    this.loginName = this.store.getLoginName()
  }

  toggleSlider() {
    this.sliderActive = !this.sliderActive;
  }

  submitChangePassword() {
    const newPass = this.changePasswordForm.get('newPassword')?.value
    const confirmPass = this.changePasswordForm.get('confirmPassword')?.value
    if (newPass !== confirmPass) {
      this.isError = true
      this.errorMessage = 'Password does not match'
    } else {
      this.isError = false
      this.updatePassword(confirmPass)
    }
  }

  updatePassword(newPassword: any) {
    this.isUploading = true
    this, this.service.getUpdatePassword(newPassword).subscribe({
      next: (value) => {
        const loginName = this.store.getLoginName()
        var authorization = 'Basic ' + window.btoa(loginName + ':' + newPassword);
        this.store.setAuth(authorization)

        alert("Success")
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        $('#changePasswordModal').modal('hide');
        this.isError = false
        this.errorMessage = ''
      },
    })
  }

  submitAdminLogout() {
    this.isUploading = true
    this.service.getLogout().subscribe({
      next: () => {
        sessionStorage.clear()
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        $('#logoutModal').modal('hide');
        this.router.navigate(["/login"])
      }
    })

  }
}
