import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginApiService {

  constructor(private http: HttpClient) { }

  getLogin(authorization: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': authorization,
      }),
      withCredentials: true,
      // observe: 'response' as 'response',
    }

    return this.http.post(environment.baseUrl + 'api/drapp/user/login', null, httpOptions)

  }

  getOtpCode(name: string): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post(environment.baseUrl + 'api/drapp/user/forgotpassword/start?loginName=' + name, null, httpOptions)

  }

  getNewPassword(loginName: string, otpCode: string): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post(environment.baseUrl + 'api/drapp/user/forgotpassword/end?loginName=' + loginName + '&validateSms=' + otpCode, null, httpOptions)

  }
  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
    }
    return result;
  }

}
