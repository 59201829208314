import { DealerApiService } from './../../services/dealer_service';
import { StoreData } from 'src/app/utils/store_data';
import { Component } from '@angular/core';
import { AdminApiService } from 'src/app/services/admin_service';
import { Refrsh } from 'src/app/utils/refresh';
import { DealerInfoModel } from 'src/app/models/dealer_info_model';
import { AdminInfoModel } from 'src/app/models/admin_info_model';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-profile',
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {

  dealerInfoData!: DealerInfoModel
  adminInfoData!: AdminInfoModel
  isDealer = true

  constructor(
    private adminService: AdminApiService,
    private dealerService: DealerApiService,
    private refresh: Refrsh,
    private store: StoreData) {

  }

  ngOnInit(): void {
    if (this.store.getRole() === UserLoginRole.DEALER) {
      this.isDealer = true
      this.getDealerInfo()
    } else {
      this.isDealer = false
      this.getAdminInfo()
    }

  }

  getDealerInfo() {
    this.dealerService.getDealerInfoByName(this.store.getLoginName()).subscribe({
      next: (response) => {
        this.dealerInfoData = response
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
      },
    })
  }

  getAdminInfo() {
    this.adminService.getAdminInfoBySelf().subscribe({
      next: (response) => {
        this.adminInfoData = response
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
      },
    })
  }
}
