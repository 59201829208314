import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularImageViewerModule } from '@hreimer/angular-image-viewer';
import { RebateType } from 'src/app/enumeration/rebateType';
import { CreditNotesApiService } from 'src/app/services/credit_notes_service';
import { PriceListApiService } from 'src/app/services/pricelist_service';
import { RebateApiService } from 'src/app/services/rebate_service';
import { SaleSystemApiService } from 'src/app/services/sale_system_service';
import { Refrsh } from 'src/app/utils/refresh';

@Component({
  selector: 'app-image-viewer',
  imports: [
    CommonModule,
    RouterModule,
    AngularImageViewerModule
  ],
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent {

  src: any = [];
  link: any
  type: any
  name: any
  fileName: any
  imageIndex = 0;
  rebateType = RebateType.SALES_REBATE

  setting = {
    btnContainerClass: 'customer-btn',
    loading: true,
    fullscreen: true,
    btnClass: 'default',
    zoomFactor: 0.1,
    containerBackgroundColor: '#ffffff00',
    allowFullscreen: true,
    allowKeyboardNavigation: false,
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: false,
      next: false,
      prev: false,
      reset: true,
      customBtns: true
    },
    btnIcons: {
      customBtns: {
        classes: 'fas fa-plus',
        //text: 'zoom_in'
      },
      zoomIn: {
        classes: 'fas fa-plus',
        //text: 'zoom_in'
      },
      zoomOut: {
        classes: 'fas fa-minus',
        //text: 'zoom_out'
      },
      rotateClockwise: {
        classes: 'fas fa-repeat',
        // text: 'rotate_right'
      },
      rotateCounterClockwise: {
        classes: 'fas fa-undo',
        //text: 'rotate_left'
      },
      fullscreen: {
        classes: 'fas fa-arrows-alt',
        //text: 'fullscreen'
      },
      reset: {
        classes: 'fas fa-undo',
        // text: 'restore'
      }
    },
    customBtns: [
      {
        name: 'link',
        icon: {
          classes: 'fas fa-download',
          //text: 'link'
        }
      }
    ]
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private refresh: Refrsh,
    private saleSystemService: SaleSystemApiService,
    private creditNotesService: CreditNotesApiService,
    private rebateService: RebateApiService,
    private priceListService: PriceListApiService
  ) {

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.type = String(routeParams.get('type'));
    this.name = String(routeParams.get('imgName'));
    this.fileName = String(routeParams.get('imgFileName'));
    if (this.type === 'SaleSystem') {
      this.getSaleSystemDownloadFile()
    } else if (this.type === 'CreditNote') {
      this.getCreditNotesDownloadFile()
    } else if (this.type === 'Rebate') {
      this.rebateType = RebateType.SALES_REBATE
      this.getRebateDownloadFile()
    } else if (this.type === 'Premier') {
      this.rebateType = RebateType.PREMIER_POINT
      this.getRebateDownloadFile()
    } else {
      this.getPriceListDownloadFile()
    }

  }

  getSaleSystemDownloadFile() {
    this.saleSystemService.getDownloadFile(this.name).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    })
  }

  getCreditNotesDownloadFile() {
    this.creditNotesService.getDownloadFile(this.name).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    })
  }

  getRebateDownloadFile() {
    this.rebateService.getDownloadFile(this.name, this.rebateType).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    })
  }

  getPriceListDownloadFile() {
    this.priceListService.getDownloadFile(this.name).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    })
  }

  getPdffile(data: any) {
    const blob = new Blob([data], { type: 'image/jpeg' });
    const url = URL.createObjectURL(blob);
    this.src.push(url)

    // var blob = data as Blob
    // var url = window.URL.createObjectURL(blob)
    //window.open(url)
  }

  downloadPDFFile() {
    const download = document.createElement('a');
    download.href = this.src
    download.setAttribute('download', this.fileName);
    download.click();
  }

  handleEvent(event: any) {
    this.downloadPDFFile()
  }
}
