<div class="d-flex">
  <div class="col-sm-8 col-md-8 col-lg-8 table-responsive mt-3 rounded-3">
    <table class="table">
      <thead>
        <tr>
          <th>SrNo</th>
          <th>Brand Name</th>
          <th>Purchasing Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of dataList ; index as i">
          <td>{{i+1}}</td>
          <td>{{data.brandName}}</td>
          <td>{{setValue(data.amountString)}} Ks</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-sm-4 col-md-4 col-lg-4">
    <div class="card bg-white shadow-sm rounded-3 mt-3">
      <div class="card-header">
        Filter By
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label text-secondary">Quater Name</label>
          <input type="text" class="form-control" [(ngModel)]="quarterName" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label text-secondary">Dealer Login Name</label>
          <input type="text" class="form-control" [(ngModel)]="loginName" [ngbTypeahead]="searchList">
        </div>
        <div class="mb-3" *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
        <div class="text-right mt-3">
          <button type="button" class="btn btn-outline-warning" (click)="clearData()">Clear</button>
          <button type="button" class="btn btn-outline-primary ml-3" (click)="searchByName()" [disabled]="!loginName">Search</button>
        </div>
      </div>
    </div>
  </div>
</div>
