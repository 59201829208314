<div class="row" *ngIf="isCreateUpdateRemoveAccess">
  <div class="col">
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createQuaterModal">
      Create
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>SrNo</th>
        <th>Name</th>
        <th>Description</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Status</th>
        <th *ngIf="isCreateUpdateRemoveAccess"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of quaterList |
      paginate: { itemsPerPage: pageSize, currentPage: page + 1, totalItems: allTotal }">
        <td (click)="goToPurchasingPower(data.name)">{{ data.srNo }}</td>
        <td (click)="goToPurchasingPower(data.name)">{{ data.name }}</td>
        <td (click)="goToPurchasingPower(data.name)">{{ data.description }}</td>
        <td (click)="goToPurchasingPower(data.name)">{{ data.beginDate }}</td>
        <td (click)="goToPurchasingPower(data.name)">{{ data.endDate }}</td>
        <td (click)="goToPurchasingPower(data.name)">{{ data.quarterStatus }}</td>
        <td *ngIf="isCreateUpdateRemoveAccess">
          <div style="text-align: right">
            <button type="button" class="btn btn-primary" (click)="openUpdateModel(data)">Update</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>


<!-- Create Quarter Model -->
<div class="modal" id="createQuaterModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create New Quarter</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="createQuaterForm">
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
          <div class="mb-3">
            <label class="form-label">Start Date (DD/MM/YYYY)</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="beginTs" placeholder="DD/MM/YYYY" ngbDatepicker
                #dstart="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dstart.toggle()" type="button">
                  <i class="bi bi-calendar3"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">End Date (DD/MM/YYYY)</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="endTs" placeholder="DD/MM/YYYY" ngbDatepicker
                #dend="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dend.toggle()" type="button">
                  <i class="bi bi-calendar3"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Quarter Status</label>
            <select class="form-select" formControlName="quarterStatus">
              <option *ngFor="let type of quaterStatus" [value]="type">{{ type }}</option>
            </select>
          </div>
        </form>
        <div *ngIf="isCreateError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="createQuater()" [disabled]="!createQuaterForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Quarter Model -->
<div class="modal" tabindex="-1" [ngStyle]="{ display: displayStyle }" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Quarter</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="updateQuaterForm">
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
          <div class="mb-3">
            <label class="form-label">Start Date (DD/MM/YYYY)</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="beginTs" placeholder="DD/MM/YYYY" ngbDatepicker
                #dupdatestart="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dupdatestart.toggle()" type="button">
                  <i class="bi bi-calendar3"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">End Date (DD/MM/YYYY)</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="endTs" placeholder="DD/MM/YYYY" ngbDatepicker
                #dupdateend="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dupdateend.toggle()" type="button">
                  <i class="bi bi-calendar3"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Quarter Status</label>
            <select class="form-select" formControlName="quarterStatus">
              <option *ngFor="let type of quaterStatus" [value]="type">{{ type }}</option>
            </select>
          </div>
        </form>
        <div *ngIf="isUpdateError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModel()">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateQuater()" [disabled]="!updateQuaterForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
