import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { RebateType } from 'src/app/enumeration/rebateType';
import { CreditNotesApiService } from 'src/app/services/credit_notes_service';
import { PriceListApiService } from 'src/app/services/pricelist_service';
import { RebateApiService } from 'src/app/services/rebate_service';
import { SaleSystemApiService } from 'src/app/services/sale_system_service';
import { Refrsh } from 'src/app/utils/refresh';

@Component({
  selector: 'app-pdf-viewer',
  imports: [
    CommonModule,
    RouterModule,
    NgxExtendedPdfViewerModule
  ],
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent {

  src: any = '';
  link: any
  type: any
  name: any
  fileName: any
  show: any = false
  rebateType = RebateType.SALES_REBATE
  loading: any = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private refresh: Refrsh,
    private saleSystemService: SaleSystemApiService,
    private creditNotesService: CreditNotesApiService,
    private rebateService: RebateApiService,
    private priceListService: PriceListApiService,
    private pdfService: NgxExtendedPdfViewerService,
  ) {

    pdfDefaultOptions.doubleTapZoomFactor = '150%'; // The default value is '200%'
    pdfDefaultOptions.maxCanvasPixels = 4096 * 4096 * 5; // The default value is 4096 * 4096 pixels,
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.type = String(routeParams.get('type'));
    this.name = String(routeParams.get('pdfName'));
    this.fileName = String(routeParams.get('pdfFileName'));
    if (this.type === 'SaleSystem') {
      this.getSaleSystemDownloadFile()
    } else if (this.type === 'CreditNote') {
      this.getCreditNotesDownloadFile()
    } else if (this.type === 'Rebate') {
      this.rebateType = RebateType.SALES_REBATE
      this.getRebateDownloadFile()
    } else if (this.type === 'Premier') {
      this.rebateType = RebateType.PREMIER_POINT
      this.getRebateDownloadFile()
    } else {
      this.getPriceListDownloadFile()
    }
  }

  getSaleSystemDownloadFile() {
    this.loading = true
    this.saleSystemService.getDownloadFile(this.name).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }

      },
      complete: () => {
        this.loading = false
        this.show = true
      },
    })
  }

  getCreditNotesDownloadFile() {
    this.loading = true
    this.creditNotesService.getDownloadFile(this.name).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
        this.show = true
      },
    })
  }

  getRebateDownloadFile() {
    this.loading = true
    this.rebateService.getDownloadFile(this.name, this.rebateType).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }

      },
      complete: () => {
        this.loading = false
        this.show = true
      },
    })
  }

  getPriceListDownloadFile() {
    this.loading = true
    this.priceListService.getDownloadFile(this.name).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }

      },
      complete: () => {
        this.loading = false
        this.show = true
      },
    })
  }

  getPdffile(data: any) {
    const blob = new Blob([data], { type: 'image/pdf' });
    const url = URL.createObjectURL(blob);
    this.src = url

    // var blob = data as Blob
    // var url = window.URL.createObjectURL(blob)
    // window.open(url)

  }

}
