import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StoreData } from '../utils/store_data';
import { TokenRefresh } from './tokenRefresh_service';

@Injectable({
  providedIn: 'root'
})
export class PriceListApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh) { }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getPriceList(): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/pricelist/registration/listpricelistinfo', httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getCreatePriceList(file: any, name: any, des: any, date: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    //const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/pricelist/registration/upload?priceListName=' + name + '&description=' + des + '&effectiveDate=' + date, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getUpdatePriceList(name: string, des: any, date: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/pricelist/registration/update?priceListName=' + name + '&description=' + des + '&effectiveDate=' + date, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getRemovePriceList(name: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/pricelist/registration/remove?priceListName=' + name, null, httpOptions)

  }

  getDownloadFile(name: string): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/pricelist/registration/downloadpricelist?priceListName=' + name, { headers, withCredentials: true, responseType: 'blob' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
