import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageSize } from 'src/app/enumeration/pageSize';
import { RecordBreakingApiService } from 'src/app/services/record_breaking_service';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';

@Component({
  selector: 'app-purchasing-power-list',
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule
  ],
  templateUrl: './purchasing-power-list.component.html',
  styleUrls: ['./purchasing-power-list.component.css']
})
export class PurchasingPowerListComponent {
  precentList: any = []
  pageSize = PageSize.PURCHASING_LIST
  page = 0
  allTotal = 0
  loading = false
  brandNameSet: any = []

  constructor(
    private router: Router,
    private refresh: Refrsh,
    private service: RecordBreakingApiService,
    private store: StoreData) {

  }

  ngOnInit(): void {
    this.getAllPercentList()
  }

  getAllPercentList() {
    this.loading = true
    this.service.getListPurchaseInfo(this.page, this.pageSize).subscribe({
      next: (response) => {
        this.precentList = response.quarterlyPurchaseInfoRespLst
        this.allTotal = response.allTotal;

        //this.brandNameSet = this.precentList.brandNameAmountStringLst.map((data: { brandName: any; }) => data.brandName);
        this.brandNameSet = []
        for (const dealer of this.precentList) {
          const dealerBrandNames: string[] = [];
          for (const brand of dealer.brandNameAmountLst) {
            dealerBrandNames.push(brand.brandName);
          }
          dealerBrandNames.sort().join(" ,")
          this.brandNameSet.push(dealerBrandNames);
        }
      },
      error: (e) => {
        if (e) {
          if (e.includes('Active present quarter name not found in db')) {
            alert('Purchasing data not found for dealer')
          } else {
            alert(e)
          }
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })

  }

  goToChart(data: any, shopName: any) {
    //this.router.navigate(['/nav/purchasingChart'], { queryParams: { data: JSON.stringify(data) } });
    this.router.navigate(['/nav/purchasingChart', JSON.stringify(data), shopName], { skipLocationChange: true });
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllPercentList();
    }
  }
}
