<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="d-flex flex-wrap align-items-center justify-content-center mt-5">
      <div class="mr-5">
        <img src="/assets/appicon.png" class="boder border-white" alt="UPG logo" width="230"
          height="230" />
      </div>
      <div class="col col-sm-6 col-md-6 col-lg-4">
        <table class="borderless">
          <tr>
            <td colspan="2">
              <h3>UPG Dealer Portal</h3>
            </td>
          </tr>
          <tr>
            <td>Login Time</td>
            <td class="font-weight-bold">{{date}}</td>
          </tr>
          <tr>
            <td>Username</td>
            <td class="font-weight-bold">{{username}}</td>
          </tr>
          <tr>
            <td>User Type</td>
            <td class="font-weight-bold">{{role | uppercase}}</td>
          </tr>
          <tr *ngIf="isSMSCheckAccess">
            <td><a class="btn btn-sm colorBlue text-white" (click)="checksmsbalance()">
                Check sms balance
              </a></td>
            <td class="font-weight-bold">{{smsbalance}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="top-content mt-5">
      <div class="container-fluid">
        <ngb-carousel *ngIf="images" [showNavigationArrows]="true" [showNavigationIndicators]="true" [interval]="3000">
          <ng-template ngbSlide *ngFor="let _ of [].constructor(3); let slideIndex = index">
            <div class="carousel-item-wrapper d-flex justify-content-between">
              <div *ngFor="let image of getImagesForSlide(slideIndex)" class="item-padding">
                <img src="{{ image.src }}" alt="{{ image.alt }}" class="img-fluid roundedR">
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>

  <footer>
    <div class="d-flex align-items-center justify-content-center mt-5">
      <div class="mt-5">
        Our Social Links
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <div>
        <a href="https://www.facebook.com/profile.php?id=100064690988374" target="_blank" class="btn btn-link">
          <img src="/assets/social/facebook.png" alt="FaceBook" width="50" height="50">
        </a>
      </div>
      <div>
        <a href="http://instagram.com/upgpaint?igshid=YmMymTA2M2Y=" target="_blank" class="btn btn-link">
          <img src="/assets/social/instagram.png" alt="Instagram" width="50" height="50">
        </a>
      </div>
      <div>
        <a href="https://invite.viber.com/?g2=AQBU2wEQbm5mC0%2Bcqh859g3sOlOIPI1MdRwr9N7iLI5uGl%2B5Pj9Zf7VH%2B3mtYgqh&lang=en" target="_blank" class="btn btn-link">
          <img src="/assets/social/viber.png" alt="YouTube" width="50" height="50">
        </a>
      </div>
      <div>
        <a href="https://www.youtube.com/@upgunitedpaintsgroup689" target="_blank" class="btn btn-link">
          <img src="/assets/social/youtube.png" alt="YouTube" width="50" height="50">
        </a>
      </div>
    </div>
  </footer>
</div>
