<div class="d-flex">
  <div class="mr-auto">
    <a class="btn btn-info" routerLink="/nav/dealer">Back</a>
  </div>
  <div class="pl-3" *ngIf="isAddRemoveBrandSaleSystem">
    <button type="button" class="btn btn-primary" (click)="openPopupAddBrand()">Add Brand</button>
  </div>
  <div class="pl-3" *ngIf="isAddRemoveBrandSaleSystem">
    <button type="button" class="btn btn-danger" (click)="openPopupRemoveBrand()"
      [disabled]="removeBrandList.length<=0">Remove Brand</button>
  </div>
  <div class="pl-3" *ngIf="isAddRemoveBrandSaleSystem">
    <button type="button" class="btn btn-primary" (click)="openPopupSaleSystem()">Sale System</button>
  </div>
  <div class="pl-3" *ngIf="isUpdateInfoVerifyPhoneAccess">
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#updateVerifyPhoneModel">Update
      Verify Phone</button>
  </div>
  <div class="pl-3" *ngIf="isUpdateInfoVerifyPhoneAccess">
    <button type="button" class="btn btn-primary" (click)="update()">Update Info</button>
  </div>
  <div class="pl-3" *ngIf="isEnableDisableAccess">
    <button type="button" class="btn btn-primary" *ngIf="!isEnabled" (click)="enabled()">Enabled</button>
    <button type="button" class="btn btn-danger" *ngIf="isEnabled" (click)="disabled()">Disabled</button>
  </div>
</div>

<ng-container ng *ngIf="data">

</ng-container>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <td scope="col" colspan="2">{{loginName}} Information</td>
    </thead>
    <tbody>
      <tr>
        <td class="w-25">Login Name</td>
        <td class="w-75">{{ data.dealerLoginName }}</td>
      </tr>
      <tr>
        <td>UPG Dealer ID</td>
        <td>{{ data.sapDealerId }}</td>
      </tr>
      <tr>
        <td>Brand</td>
        <td>{{ data.brandSet ? data.brandSet.join(' , ' ) : '' }}</td>
      </tr>
      <tr>
        <td>Sales System</td>
        <td *ngIf="data.salesSystemName === 'null'">{{ '' }}</td>
        <td *ngIf="data.salesSystemName !== 'null'">{{ data.salesSystemName }}</td>
      </tr>
      <tr>
        <td>Contact Person Name</td>
        <td>{{ data.contactPersonName }}</td>
      </tr>
      <tr>
        <td>Title</td>
        <td>{{ data.title }}</td>
      </tr>
      <tr>
        <td>NRC</td>
        <td>{{ data.nrc }}</td>
      </tr>
      <tr>
        <td>Shop Company Name</td>
        <td>{{ data.shopCompanyName }}</td>
      </tr>
      <tr>
        <td>Company Register No</td>
        <td>{{ data.companyRegisterNo }}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{ data.email }}</td>
      </tr>
      <tr>
        <td>Verify Phone</td>
        <td>{{ data.dealerVerifyPhone }}</td>
      </tr>
      <tr>
        <td>Contact Phone</td>
        <td>{{ data.contactPhone }}</td>
      </tr>
      <tr>
        <td>Viber Phone</td>
        <td>{{ data.viberPhone }}</td>
      </tr>
      <tr>
        <td>Address</td>
        <td>{{ [data.addressLine1, data.addressLine2, data.addressLine3, data.addressLine4, data.addressLine5].join(" ")}}</td>
      </tr>
      <tr>
        <td>City Code</td>
        <td>{{ data.cityCode }}</td>
      </tr>
      <tr>
        <td>City Name</td>
        <td>{{ data.cityName }}</td>
      </tr>
      <tr>
        <td>Sales District Code</td>
        <td>{{ data.salesDistrictCode }}</td>
      </tr>
      <tr>
        <td>Township</td>
        <td>{{ data.township }}</td>
      </tr>
      <tr>
        <td>Region</td>
        <td>{{ data.region }}</td>
      </tr>
      <tr>
        <td>Division State</td>
        <td>{{ data.divisionState }}</td>
      </tr>
      <tr>
        <td>Login Enabled</td>
        <td>{{ data.enabled }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!--Sale System Modal -->
<div class="modal" tabindex="-1" aria-hidden="true" [ngStyle]="{ display: displaySaleSystemStyle }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add or Remove Sale System</h5>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">Select Sale System Name</label>
            <select class="form-select" [(ngModel)]="saleSystemName">
              <option *ngFor="let type of saleSystemList" [value]="type">{{ type }}</option>
            </select>
          </div>
          <div *ngIf="isSaleSystemError">
            <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closePopup()">
          Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="removeSaleSystem()" [disabled]="!saleSystemName">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Remove</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="addSaleSystem()" [disabled]="!saleSystemName">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Add</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!--Add Brand Modal -->
<div class="modal" tabindex="-1" aria-hidden="true" [ngStyle]="{ display: displayAddBrandStyle }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Brand</h5>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">Select Brand Name</label>
            <select class="form-select" [(ngModel)]="addBrandName">
              <option *ngFor="let type of addBrandList" [value]="type">{{ type }}</option>
            </select>
          </div>
          <div *ngIf="isSaleSystemError">
            <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closePopup()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="addBrand()" [disabled]="!addBrand">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Remove Brand Modal -->
<div class="modal" tabindex="-1" aria-hidden="true" [ngStyle]="{ display: displayRemoveBrandStyle }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Remove Brand</h5>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">Select Brand Name</label>
            <select class="form-select" [(ngModel)]="removeBrandName">
              <option *ngFor="let type of removeBrandList" [value]="type">{{ type }}</option>
            </select>
          </div>
          <div *ngIf="isSaleSystemError">
            <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closePopup()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="removeBrand()" [disabled]="!removeBrandName">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Verify Phone Modal -->
<div class="modal" id="updateVerifyPhoneModel" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Verify Phone</h5>
      </div>
      <div class="modal-body">
        <div>
          <input type="text" class="form-control" placeholder="Please Enter New Verify Phone"
            [(ngModel)]="newVerifyPhone">
        </div>
        <div *ngIf="isVPhoneError" class="mt-3">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateVerifyPhone()" [disabled]="!newVerifyPhone">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
