<div class="d-flex">
  <div class="mr-auto">
    <a class="btn btn-info" routerLink="/nav/admin">
      <i class="bi bi-caret-left-fill"></i>Back</a>
  </div>
  <div class="pl-3">
    <button type="button" class="btn btn-primary" (click)="defaultError()" data-bs-toggle="modal"
      data-bs-target="#updateVerifyPhoneModel">Update
      Verify Phone</button>
  </div>
  <div class="pl-3">
    <button type="button" class="btn btn-primary" (click)="defaultError()" data-bs-toggle="modal"
      data-bs-target="#updateInfoModel">Update
      Info</button>
  </div>
  <div class="pl-3">
    <button type="button" class="btn btn-primary" *ngIf="!isEnabled" (click)="enabled()">Enabled</button>
    <button type="button" class="btn btn-danger" *ngIf="isEnabled" (click)="disabled()">Disabled</button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" colspan="2">{{loginName }} Information</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr>
        <td class="w-25">SrNo</td>
        <td class="w-75">{{dataInfo.srNo}}</td>
      </tr> -->
      <tr>
        <td class="w-25">Login Name</td>
        <td class="w-75">{{dataInfo.adminLoginName}}</td>
      </tr>
      <tr>
        <td>Name</td>
        <td>{{dataInfo.name}}</td>
      </tr>
      <tr>
        <td>Verify Phone</td>
        <td>{{dataInfo.adminVerifyPhone}}</td>
      </tr>
      <tr>
        <td>Phone</td>
        <td>{{dataInfo.phone}}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{dataInfo.email}}</td>
      </tr>
      <tr>
        <td>Role</td>
        <td>{{dataInfo.adminRoleSet}}</td>
      </tr>
      <tr>
        <td>Title</td>
        <td>{{dataInfo.title}}</td>
      </tr>
      <tr>
        <td>Department</td>
        <td>{{dataInfo.department}}</td>
      </tr>
      <tr>
        <td>Enabled</td>
        <td>
          <i class="fas fa-check-circle fa-lg text-success" *ngIf="dataInfo.enabled"></i>
          <i class="fas fa-times-circle fa-lg text-danger" *ngIf="!dataInfo.enabled"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Update Verify Phone Modal -->
<div class="modal" id="updateVerifyPhoneModel" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Verify Phone</h5>
      </div>
      <div class="modal-body">
        <div>
          <input type="text" class="form-control" placeholder="Please Enter New Verify Phone"
            [(ngModel)]="newVerifyPhone">
        </div>
        <div *ngIf="isVPhoneError" class="mt-3">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateVerifyPhone()" [disabled]="!newVerifyPhone">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Info Modal -->
<div class="modal" id="updateInfoModel" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Info</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <form [formGroup]="updateInfoForm">
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input type="email" class="form-control" formControlName="email">
            </div>
            <div class="mb-3">
              <label class="form-label">Phone</label>
              <input type="text" class="form-control" formControlName="phone">
            </div>
            <div class="mb-3">
              <label class="form-label">Title</label>
              <input type="text" class="form-control" formControlName="title">
            </div>
            <div class="mb-3">
              <label class="form-label">Department</label>
              <input type="text" class="form-control" formControlName="department">
            </div>
          </form>
        </div>
        <div *ngIf="isInfoError" class="mt-3">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateInfo()" [disabled]="!updateInfoForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
