import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { RebateType } from 'src/app/enumeration/rebateType';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { RebateApiService } from 'src/app/services/rebate_service';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
// import * as fs from 'fs';
declare var $: any

@Component({
  selector: 'app-premier-point-list',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './premier-point-list.component.html',
  styleUrls: ['./premier-point-list.component.css']
})
export class PremierPointListComponent {

  loading: any = false;
  premierList: any;
  loginName: any;
  searchName: any

  rebateType = RebateType.PREMIER_POINT
  pageSize = PageSize.PREMIER_LIST
  page = 0
  allTotal = 0

  createPremierForm: FormGroup<any>;
  updatePremierForm: FormGroup<any>;
  uploadPremierForm: FormGroup<any>;
  uploadZipPremierForm: FormGroup<any>

  isError: any;
  errorMessage: any;
  displayStyle: any;
  selectedName: any;

  premierName: any
  nameList: any[] = []
  selectedFile: any
  selectedZipFile: any
  isDealer = false;
  isUploading: boolean = false
  isCreateUpdateRemoveAccess: boolean = false;
  isUploadError: any = false;
  fileList: any = []

  constructor(private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private service: RebateApiService,
    private store: StoreData,
    private dateFormat: DateFormatPipe,
    private checkAccessRoles: CheckAccessRoles) {
    this.createPremierForm = this.fb.group({
      dealerLoginName: ['', Validators.required],
      description: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
    this.updatePremierForm = this.fb.group({
      effectiveDate: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.uploadPremierForm = this.fb.group({
      dealerLoginName: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
    this.uploadZipPremierForm = this.fb.group({
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isCreateUpdateRemoveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])

    if (this.store.getIsDealer()) {
      this.isDealer = true
      this.loginName = this.store.getLoginName()
      this.searchPremierListByName(0)
    } else {
      this.initData()
    }
  }
  async initData() {
    try {
      await this.getAllRebateList();
      await this.getNameList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  getAllRebateList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getRebateList('ALL', this.rebateType, this.page, this.pageSize).subscribe({
        next: (response) => {
          this.premierList = response.cNMdoRUploadRespLst
          this.allTotal = response.allTotal;
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          resolve()
          this.loading = false
        },
      })
    })
  }

  getNameList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getNameList().subscribe({
        next: (response) => {
          this.nameList = response
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          this.loading = false
          resolve()
        },
      })
    })
  }

  searchList = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.nameList.filter(item => item[1].toLowerCase().startsWith(term.toLowerCase()))
      )
    );
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }

  onFileSelectedMulti(event: any) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.fileList = Array.from(inputElement.files)
    }
  }

  async uploadFile() {
    this.isUploading = true
    const name = this.uploadPremierForm.get('dealerLoginName')?.value
    const dealerLoginName = name[0]
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.uploadPremierForm.get('effectiveDate')?.value)
    var errorFileName: any[] = []

    for (const file of this.fileList) {
      const name = file.name
      const [fileName, des] = name.split("_");
      const description = des.substring(0, des.lastIndexOf('.jpg'))
      const value = await this.createUploadFileByFile(dealerLoginName, description, date, file)
      if (String(value).includes('Error')) {
        errorFileName.push(name + value)
      } else {
        console.log("Success for", name);
      }
    }

    this.isUploading = false
    $('#uploadCSVModal').modal('hide');
    if (errorFileName.length > 0) {
      alert(errorFileName.join(" , "))
    } else {
      alert("Success")
    }
    this.refresh.refreshPage()
  }

  async createUploadFileByFile(dealerLoginName: any, description: any, date: any, file: any) {
    console.log("Api call ", file.name);

    // return true
    return new Promise((resolve, reject) => {
      this.service.getCreateRebate(file, dealerLoginName, description, date, this.rebateType).subscribe({
        next: (value) => {
          console.log('Success Api for', file.name);
          resolve('Success'); // Resolve the promise with the value
        },
        error: (e) => {
          if (e) {
            resolve('Error : ' + e)
          }
        },
        complete: () => {
        },
      });
    })
  }

  createRebate() {
    this.isUploading = true
    const dealerLoginName = this.createPremierForm.get('dealerLoginName')?.value[0]
    var description = this.createPremierForm.get('description')?.value
    // var date = this.dateFormat.convertDDMMYYYY(this.createRebateForm.get('effectiveDate')?.value)
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.createPremierForm.get('effectiveDate')?.value);

    this.service.getCreateRebate(this.selectedFile, dealerLoginName, description, date, this.rebateType).subscribe({
      next(value) {
        $('#createRebateModal').modal('hide');
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  onFileSelectedZip(event: any) {
    this.selectedZipFile = event.target.files[0]
  }

  uploadZipFile() {

    if (this.selectedZipFile.size > 250 * 1024 * 1024) {
      alert("File size exceeds the limit (250 MB). Please select a smaller file.")
      return;
    }

    this.isUploading = true

    var date = this.dateFormat.convertObjectToDDMMYYYY(this.uploadZipPremierForm.get('effectiveDate')?.value);

    this.service.getUploadZipFileRebate(this.selectedZipFile, date, this.rebateType).subscribe({
      next(value) {
        $('#uploadZipPremierModal').modal('hide');
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  checkZipUploadStatus() {
    this.service.getUploadZipFileRebateStatus().subscribe({
      next(value) {
        alert(value.Message)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
      },
    })
  }

  searchPremierListByName(page: number) {
    this.loading = true
    if (Array.isArray(this.searchName)) {
      this.loginName = this.searchName[0]
    }
    this.service.getRebateList(this.loginName, this.rebateType, page, this.pageSize).subscribe({
      next: (response) => {
        this.premierList = response.cNMdoRUploadRespLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  clearPremierListByName() {
    this.refresh.refreshPage()
  }

  openPDF(data: any) {
    this.router.navigate(['/nav/imageView', 'Premier', data.name, data.fileName])
  }

  openUpdateModel(data: any) {
    this.displayStyle = 'block'
    this.premierName = data.name
    this.updatePremierForm.patchValue({
      description: data.description,
      effectiveDate: this.dateFormat.convertDateToObject(data.effectiveDate)
    })
  }

  closeModel() {
    this.displayStyle = 'none'
  }

  updateRebate() {
    this.isUploading = true
    var description = this.updatePremierForm.get('description')?.value
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.updatePremierForm.get('effectiveDate')?.value)

    this.service.getUpdateRebate(this.premierName, description, date, this.rebateType).subscribe({
      next: (response) => {
        this.displayStyle = 'none'
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  setData(data: any) {
    this.premierName = data.name
  }

  updateFile() {
    this.isUploading = true
    this.service.getUpdateRebateFile(this.premierName, this.selectedFile, this.rebateType).subscribe({
      next: (response) => {
        $('#updateFileModal').modal('hide');
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  removeRebate(name: any) {
    this.isUploading = true
    this.service.getRemoveRebate(name, this.rebateType).subscribe({
      next: (response) => {
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        this.refresh.refreshPage()
      },
    })
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      if (this.loginName) {
        this.searchPremierListByName(this.page)
      } else {
        this.getAllRebateList();
      }
    }
  }
}

