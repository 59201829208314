import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { DealerApiService } from 'src/app/services/dealer_service';
import { Refrsh } from 'src/app/utils/refresh';

@Component({
  selector: 'app-dealer-create',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './dealer-create.component.html',
  styleUrls: ['./dealer-create.component.css']
})
export class DealerCreateComponent {

  isCreateDealer = true;
  isUpdateDealer = false
  createDealerForm: FormGroup;
  registerDealerForm: FormGroup;
  updateDealerForm: FormGroup;
  dealerTypes = ['UPG_GROUP', 'UPG_FAMILY', 'PREMIER'];

  isError: boolean = false
  errorMessage = ''
  isEnabled: boolean = true
  loginNmae = ''
  isUploading : boolean = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private service: DealerApiService,
    private refresh: Refrsh) {

    this.createDealerForm = this.fb.group({
      loginName: ['', Validators.required],
      password: ['', Validators.required],
      verifyPhone: ['959', [Validators.required, Validators.minLength(9)]],
      enabled: [false]
    });

    this.registerDealerForm = this.fb.group({
      sapDealerId: ['',Validators.required],
      contactPersonName: ['', Validators.required],
      email: ['', [Validators.email]],
      title: [''],
      nrc: [''],
      shopCompanyName: ['',Validators.required],
      companyRegisterNo: [''],
      contactPhone: ['', ],
      viberPhone: ['959', [Validators.required, Validators.minLength(9)]],
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      addressLine4: [''],
      cityCode: [''],
      cityName: [''],
      salesDistrictCode: [''],
      township: [''],
      region: [''],
      divisionState: ['']
    });

    this.updateDealerForm = this.fb.group({
     // verifyPhone:['959', [Validators.required, Validators.minLength(9)]],
      enabled: [false],
      contactPersonName: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      title: [''],
      nrc: [''],
      shopCompanyName: ['',Validators.required],
      companyRegisterNo: [''],
      contactPhone:['',],
      viberPhone: ['959', [Validators.required, Validators.minLength(9)]],
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      addressLine4: [''],
      cityCode: [''],
      cityName: [''],
      salesDistrictCode: [''],
      township: [''],
      region: [''],
      divisionState: ['']
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.loginNmae = String(routeParams.get('isCreate'));
    if (this.loginNmae == 'create') {
      this.isUpdateDealer = false
    } else {
      this.isUpdateDealer = true
      this.getDealerInfo()
    }
  }
  getDealerInfo() {
    this.service.getDealerInfoByName(this.loginNmae).subscribe({
      next: (value) => {
        this.setData(value)
        this.isEnabled = value.enabled
      },
      error: (e) => {
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isError = false
      },
    })

  }
  setData(value: any) {
    this.updateDealerForm.patchValue({
     // verifyPhone: value.dealerVerifyPhone,
      contactPersonName: value.contactPersonName,
      email: value.email,
      title: value.title,
      nrc: value.nrc,
      shopCompanyName: value.shopCompanyName,
      companyRegisterNo: value.companyRegisterNo,
      contactPhone: value.contactPhone,
      viberPhone: value.viberPhone,
      addressLine1: value.addressLine1,
      addressLine2: value.addressLine2,
      addressLine3: value.addressLine3,
      addressLine4: value.addressLine4,
      cityCode: value.cityCode,
      cityName: value.cityName,
      salesDistrictCode: value.salesDistrictCode,
      township: value.township,
      region: value.region,
      divisionState: value.divisionState
    });
  }


  createDealer() {
    this.isUploading = true
    const requestBody = {
      loginName: this.createDealerForm.get('loginName')?.value,
      password: this.createDealerForm.get('password')?.value,
      verifyPhone: this.createDealerForm.get('verifyPhone')?.value,
      roleSet: ["DEALER"],
      enabled: this.createDealerForm.get('enabled')?.value,
    };
    this.service.getCreateDealer(JSON.stringify(requestBody)).subscribe({
      next(value) {

      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isCreateDealer = false
        this.isError = false
      },
    })
  }

  registerDealer() {
    this.isUploading = true
    const loginName = this.createDealerForm.get('loginName')?.value

    const requestBody = {
      sapDealerId: this.registerDealerForm.get('sapDealerId')?.value,
      dealerType: this.registerDealerForm.get('dealerType')?.value,
      contactPersonName: this.registerDealerForm.get('contactPersonName')?.value,
      email: this.registerDealerForm.get('email')?.value,
      title: this.registerDealerForm.get('title')?.value,
      nrc: this.registerDealerForm.get('nrc')?.value,
      shopCompanyName: this.registerDealerForm.get('shopCompanyName')?.value,
      companyRegisterNo: this.registerDealerForm.get('companyRegisterNo')?.value,
      contactPhone: this.registerDealerForm.get('contactPhone')?.value,
      viberPhone: this.registerDealerForm.get('viberPhone')?.value,
      addressLine1: this.registerDealerForm.get('addressLine1')?.value,
      addressLine2: this.registerDealerForm.get('addressLine2')?.value,
      addressLine3: this.registerDealerForm.get('addressLine3')?.value,
      addressLine4: this.registerDealerForm.get('addressLine4')?.value,
      cityCode: this.registerDealerForm.get('cityCode')?.value,
      cityName: this.registerDealerForm.get('cityName')?.value,
      salesDistrictCode: this.registerDealerForm.get('salesDistrictCode')?.value,
      township: this.registerDealerForm.get('township')?.value,
      region: this.registerDealerForm.get('region')?.value,
      divisionState: this.registerDealerForm.get('divisionState')?.value
    };

    this.service.getRegisterDealer(loginName, JSON.stringify(requestBody)).subscribe({
      next(value) {

      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        alert("Success")
        this.router.navigate(['/nav/dealer'])
      },
    })
  }

  updateInfo() {
    this.isUploading = true
    const requestBody = {
      dealerLoginName: this.updateDealerForm.get('loginName')?.value ?? '',
      password: this.updateDealerForm.get('password')?.value ?? '',
      //dealerVerifyPhone: this.updateDealerForm.get('verifyPhone')?.value ?? '',
      sapDealerId: this.updateDealerForm.get('sapDealerId')?.value ?? '',
      dealerType: this.updateDealerForm.get('dealerType')?.value ?? '',
      contactPersonName: this.updateDealerForm.get('contactPersonName')?.value ?? '',
      email: this.updateDealerForm.get('email')?.value ?? '',
      title: this.updateDealerForm.get('title')?.value ?? '',
      nrc: this.updateDealerForm.get('nrc')?.value ?? '',
      shopCompanyName: this.updateDealerForm.get('shopCompanyName')?.value ?? '',
      companyRegisterNo: this.updateDealerForm.get('companyRegisterNo')?.value ?? '',
      contactPhone: this.updateDealerForm.get('contactPhone')?.value ?? '',
      viberPhone: this.updateDealerForm.get('viberPhone')?.value ?? '',
      addressLine1: this.updateDealerForm.get('addressLine1')?.value ?? '',
      addressLine2: this.updateDealerForm.get('addressLine2')?.value ?? '',
      addressLine3: this.updateDealerForm.get('addressLine3')?.value ?? '',
      addressLine4: this.updateDealerForm.get('addressLine4')?.value ?? '',
      cityCode: this.updateDealerForm.get('cityCode')?.value ?? '',
      cityName: this.updateDealerForm.get('cityName')?.value ?? '',
      salesDistrictCode: this.updateDealerForm.get('salesDistrictCode')?.value ?? '',
      township: this.updateDealerForm.get('township')?.value ?? '',
      region: this.updateDealerForm.get('region')?.value ?? '',
      divisionState: this.updateDealerForm.get('divisionState')?.value ?? '',
      enabled: this.isEnabled
    };

    this.service.getUpdateDealerInfoByName(this.loginNmae, requestBody).subscribe({
      next: (value) => {
        alert("Success")
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.backDealerInfo()
      },
    })
  }

  backDealerInfo() {
    this.router.navigate(['/nav/dealerInfo', this.loginNmae]);
  }
}


