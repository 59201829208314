import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Refrsh } from 'src/app/utils/refresh';
import { RecordBreakingApiService } from 'src/app/services/record_breaking_service';
import { StoreData } from 'src/app/utils/store_data';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-purchasing-power-chart',
  imports: [
    CommonModule,
    RouterModule,
    NgxChartsModule
  ],
  templateUrl: './purchasing-power-chart.component.html',
  styleUrls: ['./purchasing-power-chart.component.css']
})
export class PurchasingPowerChartComponent {

  view: any;
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showLabels = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  showDataLabel = false
  xAxisLabel = 'Brand Names';
  yAxisLabel = 'Percentage';
  legendTitle = " "
  colorScheme: any = {
    domain: ['#9370DB', '#87CEFA']
  };

  xAxisTickFormatting = (value: any) => {
    return value.toString();
  };

  public multi: any = [];
  data: any
  pastQuarterName: any
  presentQuarterName: any
  shopName: any = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private refresh: Refrsh,
    private service: RecordBreakingApiService,
    private store: StoreData
  ) {

  }

  ngOnInit(): void {

    var jsonData = this.route.snapshot.paramMap.get('jsonData')
    this.data = JSON.parse(jsonData!)
    this.shopName = this.route.snapshot.paramMap.get('shopName')
    // console.log(this.data);
    this.data.sort((a: { brandName: string; }, b: { brandName: string; }) => {
      const brandNameA = a.brandName.toLowerCase();
      const brandNameB = b.brandName.toLowerCase();
      return brandNameA.localeCompare(brandNameB);
    });

    this.pastQuarterName = this.data[0].pastQuarterName
    this.presentQuarterName = this.data[0].presentQuarterName

    for (const item of this.data) {
      const series = [
        { name: this.pastQuarterName, value: item.pastQuarterPurchase === '0' ? 0 : 100 },
        { name: this.presentQuarterName, value: parseInt(item.recordBreakingPercent) }
      ];
      this.multi.push({
        name: item.brandName,
        pastAmount: parseInt(item.pastQuarterPurchase),
        presentAmount: parseInt(item.presentQuarterPurchase),
        percent: parseFloat(item.recordBreakingPercent),
        series
      });
    }
  }
}



