import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { SessionKeys } from 'src/app/enumeration/sessionKeys';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
import { CreditNoteSession } from './../../models/credit_note_session_model';
import { CreditNotesApiService } from './../../services/credit_notes_service';
declare var $: any

@Component({
  selector: 'app-credit-note',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.css']
})
export class CreditNoteComponent {

  creditNotesList: any = []
  pageSize = PageSize.CREDIT_NOTES_LIST
  page = 0
  allTotal = 0
  loading = false
  createCreditNotesForm: FormGroup<any>;
  updateCreditNotesForm: FormGroup<any>;
  uploadZipCreditNoteForm: FormGroup<any>
  isError = false
  errorMessage = ''
  selectedFile!: File
  selectedZipFile!: File
  displayStyle: string = 'none';
  creditNotesName = ''
  nameList: any[] = []
  loginName: any = 'ALL';
  searchName: any = ''
  isDealer = false
  isUploading = false
  isCreateUpdateRemoveAccess: boolean = false;
  fileList: any = []
  isUploadError: any
  uploadCreditNoteForm: FormGroup<any>

  data: CreditNoteSession = {}

  constructor(private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private store: StoreData,
    private service: CreditNotesApiService,
    private dateFormat: DateFormatPipe,
    private checkAccessRoles: CheckAccessRoles) {

    this.createCreditNotesForm = this.fb.group({
      dealerLoginName: ['', Validators.required],
      description: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
    this.updateCreditNotesForm = this.fb.group({
      effectiveDate: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.uploadCreditNoteForm = this.fb.group({
      dealerLoginName: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
    this.uploadZipCreditNoteForm = this.fb.group({
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isCreateUpdateRemoveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.FINANCE_UPLOAD_ADMIN])

    const creditNoteDataString = sessionStorage.getItem(SessionKeys.CREDIT_NOTE)!;
    this.data = JSON.parse(creditNoteDataString) as CreditNoteSession;
    if (this.data) {
      this.page = this.data.page || 0
      if (this.data.searchName) {
        if (this.data.searchName != 'ALL') {
          this.loginName = this.data.searchName
        }
      }
    }

    if (this.store.getIsDealer()) {
      this.isDealer = true
      this.loginName = this.store.getLoginName()
      this.searchCreditNotesListByName(this.page)
    } else {
      this.initData()
    }
  }

  async initData() {
    try {
      await this.getAllCreditNotesList();
      await this.getNameList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  async getAllCreditNotesList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getCreditNotesList(this.loginName, this.page, this.pageSize).subscribe({
        next: (response) => {
          this.creditNotesList = response.cNMdoRUploadRespLst
          this.allTotal = response.allTotal;
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          // this.getNameList()
          this.loading = false
          resolve()
        },
      })
    });

  }

  async getNameList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getNameList().subscribe({
        next: (response) => {
          this.nameList = response
          if (this.data) {
            if (this.data.searchName != 'ALL') {
              this.searchName = this.nameList.filter(item => item[0].toLowerCase() === this.data.searchName.toLowerCase()).toString();
            }
          }
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          resolve()
          this.loading = false
        },
      })
    });
  }

  searchList = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.nameList.filter(item => item[1].toLowerCase().startsWith(term.toLowerCase()))
      )
    );
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }

  onFileSelectedMulti(event: any) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.fileList = Array.from(inputElement.files)
    }
  }

  async uploadFile() {
    this.isUploading = true
    const name = this.uploadCreditNoteForm.get('dealerLoginName')?.value
    const dealerLoginName = name[0]
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.uploadCreditNoteForm.get('effectiveDate')?.value)
    var errorFileName: any[] = []

    for (const file of this.fileList) {
      const name = file.name
      const [fileName, des] = name.split("_");
      const description = des.substring(0, des.lastIndexOf('.jpg'))
      const value = await this.createUploadFileByFile(dealerLoginName, description, date, file)
      // console.log(value);

      if (String(value).includes('Error')) {
        errorFileName.push(name + value)
      } else {
        console.log("Success for", name);
      }
    }
    this.isUploading = false
    $('#uploadCSVModal').modal('hide');
    if (errorFileName.length > 0) {
      alert(errorFileName.join(" , "))
    } else {
      alert("Success")
    }
    this.clearCreditNotesList()
  }

  async createUploadFileByFile(dealerLoginName: any, description: any, date: any, file: any) {
    console.log("Api call ", file.name);

    // return true
    return new Promise((resolve, reject) => {
      this.service.getCreateCreditNotes(file, dealerLoginName, description, date).subscribe({
        next: (value) => {
          console.log('Success Api for', file.name);
          resolve('Success'); // Resolve the promise with the value
        },
        error: (e) => {
          if (e) {
            resolve('Error : ' + e)
          }
        },
        complete: () => {
        },
      });
    })
  }

  createCreditNotes() {
    this.isUploading = true
    const name = this.createCreditNotesForm.get('dealerLoginName')?.value
    const dealerLoginName = name[0]
    var description = this.createCreditNotesForm.get('description')?.value
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.createCreditNotesForm.get('effectiveDate')?.value)

    this.service.getCreateCreditNotes(this.selectedFile, dealerLoginName, description, date).subscribe({
      next: (value) => {
        $('#createCreditNotesModal').modal('hide');
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.clearCreditNotesList()
      },
    })
  }
  onFileSelectedZip(event: any) {
    this.selectedZipFile = event.target.files[0]
  }

  uploadZipFile() {

    if (this.selectedZipFile.size > 250 * 1024 * 1024) {
      alert("File size exceeds the limit (250 MB). Please select a smaller file.")
      return;
    }

    this.isUploading = true

    var date = this.dateFormat.convertObjectToDDMMYYYY(this.uploadZipCreditNoteForm.get('effectiveDate')?.value);

    this.service.getUploadZipFileRebate(this.selectedZipFile, date).subscribe({
      next(value) {
        $('#uploadZipCreditNoteModal').modal('hide');
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.clearCreditNotesList()
      },
    })
  }

  checkZipUploadStatus() {
    this.service.getUploadZipFileRebateStatus().subscribe({
      next(value) {
        alert(value.Message)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
      },
    })
  }

  searchCreditNotesListByName(page: number) {
    this.loading = true

    if (Array.isArray(this.searchName)) {
      this.loginName = this.searchName[0]
    }

    this.service.getCreditNotesList(this.loginName, page, this.pageSize).subscribe({
      next: (response) => {
        this.creditNotesList = response.cNMdoRUploadRespLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  clearCreditNotesList() {
    sessionStorage.removeItem(SessionKeys.CREDIT_NOTE);
    this.refresh.refreshPage()
  }

  openPDF(data: any) {
    this.router.navigate(['/nav/imageView', 'CreditNote', data.name, data.fileName])
  }

  openUpdateModel(data: any) {
    this.displayStyle = 'block'
    this.creditNotesName = data.name
    this.updateCreditNotesForm.patchValue({
      description: data.description,
      effectiveDate: this.dateFormat.convertDateToObject(data.effectiveDate)
    })
  }

  closeModel() {
    this.displayStyle = 'none'
  }

  updateCreditNotes() {
    this.isUploading = true
    var description = this.updateCreditNotesForm.get('description')?.value
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.updateCreditNotesForm.get('effectiveDate')?.value)
    this.service.getUpdateCreditNotes(this.creditNotesName, description, date).subscribe({
      next: (response) => {
        this.displayStyle = 'none'
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.clearCreditNotesList()
      },
    })
  }

  setData(data: any) {
    this.creditNotesName = data.name
  }

  updateFile() {
    this.isUploading = true
    this.service.getUpdateCreditNoteFile(this.creditNotesName, this.selectedFile).subscribe({
      next: (response) => {
        $('#updateFileModal').modal('hide');
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.clearCreditNotesList()
      },
    })
  }

  removeCreditNote(name: any) {
    this.isUploading = true
    this.service.getRemoveCreditNotes(name).subscribe({
      next: (response) => {
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        this.clearCreditNotesList()
      },
    })
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      if (this.loginName !== 'ALL') {
        this.searchCreditNotesListByName(this.page)
      } else {
        this.getAllCreditNotesList();
      }

    }
  }
}

