import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { AdminApiService } from 'src/app/services/admin_service';
import { Refrsh } from 'src/app/utils/refresh';
import { MultiSelectCheckboxComponent } from "../../multi-select-checkbox/multi-select-checkbox.component";
declare var $: any;

@Component({
  selector: 'app-admin',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MultiSelectCheckboxComponent
],
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  loading: boolean = false
  isCreateAdmin: boolean = true
  isError: boolean = false
  fileName: string = '';
  downloadFileName: string = '';
  errorMessage = ''
  dataList: any = []
  selectedFile!: File
  createAdminForm: FormGroup
  registerAdminForm: FormGroup
  uplaodAdminCSVForm: FormGroup
  //adminTypes = ['SAP_UPLOAD_ADMIN', 'ADMIN', 'PRICELIST_UPLOAD_ADMIN', 'MDOFFICE_UPLOAD_ADMIN', 'FINANCE_UPLOAD_ADMIN'];
  adminTypes = Object.values(UserLoginRole).filter(role => !['DEALER', 'SUPER_ADMIN', 'SAP_UPLOAD_ADMIN'].includes(role))
  role: any = []
  page: number = 0
  pageSize: number = PageSize.ADMIN_LIST
  allTotal: number = 0
  list: any[] = this.adminTypes.map(adminType => {
    return { name: adminType, checked: false };
  });
  isUploadError: any
  isUploading: boolean = false

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private service: AdminApiService) {
    this.createAdminForm = this.fb.group({
      loginName: ['', Validators.required],
      password: ['', Validators.required],
      verifyPhone: ['959', [Validators.required, Validators.minLength(9)]],
      enabled: [false]
    });

    this.registerAdminForm = this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      department: ['', Validators.required],
      phone: ['959', [Validators.required, Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.email]]
    });

    this.uplaodAdminCSVForm = this.fb.group({
      type: ['', Validators.required],
      file: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getAllAdminList()
  }

  getAllAdminList() {
    this.loading = true
    this.service.getListAdmin(this.page, this.pageSize).subscribe({
      next: (response) => {
        this.dataList = response.adminInfoLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name
  }

  uploadFile() {
    var type = this.uplaodAdminCSVForm.get('type')?.value
    if (type === 'ALL') {
      if (confirm('Are you sure you want to upload the overwrite mode.This action cannot be undone.')) {
        this.uploadFileToServer(type)
      } else {

      }
    } else {
      this.uploadFileToServer(type)
    }
  }

  uploadFileToServer(type: any) {
    this.isUploading = true
    this.service.getUploadCSVAdmin(type, this.selectedFile).subscribe({
      next: (value) => {
        alert("Success")
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isUploadError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.fileName = ''
        $('#uploadCSVModal').modal('hide');
        this.refresh.refreshPage()
      },
    })
  }

  createAdmin() {
    this.isUploading = true
    const requestBody = {
      loginName: this.createAdminForm.get('loginName')?.value,
      password: this.createAdminForm.get('password')?.value,
      verifyPhone: this.createAdminForm.get('verifyPhone')?.value,
      roleSet: this.role,
      enabled: this.createAdminForm.get('enabled')?.value,
    };
    this.service.getCreateAdmin(JSON.stringify(requestBody)).subscribe({
      next(value) {

      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isCreateAdmin = false
        this.isError = false
        this.isUploading = false
      },
    })
  }

  registerAdmin() {
    this.isUploading = true

    const loginName = this.createAdminForm.get('loginName')?.value
    const requestBody = {
      name: this.registerAdminForm.get('name')?.value,
      title: this.registerAdminForm.get('title')?.value,
      department: this.registerAdminForm.get('department')?.value,
      phone: this.registerAdminForm.get('phone')?.value,
      email: this.registerAdminForm.get('email')?.value,
    };
    this.service.getRegisterAdmin(loginName, JSON.stringify(requestBody)).subscribe({
      next: (value) => {
        console.log("Success");
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        $('#createAdminModal').modal('hide');
        this.isCreateAdmin = true
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  adminInfo(loginName: any) {
    this.router.navigate(['/nav/adminInfo', loginName]);
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllAdminList();
    }
  }

  downloadFile() {
    this.isUploading = true
    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }
    this.service.getDownloadFile(this.downloadFileName).subscribe({
      next: (value) => {
        this.downloadFileInLocal(value)
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        $('#downloadCSVModal').modal('hide');
      },
    })
  }

  downloadFileInLocal(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const download = document.createElement('a');
    download.href = URL.createObjectURL(blob);
    download.setAttribute('download', this.downloadFileName);
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
    this.downloadFileName = ''
  }

  shareCheckedList(item: any[]) {
    this.role = item
  }
  shareIndividualCheckedList(item: {}) {
  }
}




