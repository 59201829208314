import { StoreData } from 'src/app/utils/store_data';
import { SaleSystemApiService } from './../../services/sale_system_service';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Refrsh } from 'src/app/utils/refresh';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { PageSize } from 'src/app/enumeration/pageSize';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
declare var $: any

@Component({
  selector: 'app-sale-system',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './sale-system.component.html',
  styleUrls: ['./sale-system.component.css']
})
export class SaleSystemComponent {

  saleSystemList: any = []
  pageSize = PageSize.SALESYSTEM_LIST
  page = 0
  allTotal = 0
  loading = false
  createSaleSystemForm: FormGroup<any>;
  isError = false
  errorMessage = ''
  selectedFile!: File
  displayStyle: string = 'none';
  saleSystemName = ''
  updateDes = ''
  isDealer = false
  isUploading: boolean = false
  isCreateUpdateRemoveAccess: any = false

  constructor(private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private store: StoreData,
    private service: SaleSystemApiService,
    private checkAccessRoles: CheckAccessRoles) {
    this.createSaleSystemForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      file: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isCreateUpdateRemoveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])

    if (this.store.getIsDealer()) {
      this.isDealer = true
      this.getSaleSystemNameForDealer()
    } else {
      this.getAllSaleSystemList()
    }
  }

  getAllSaleSystemList() {
    this.loading = true
    this.service.getSaleSystemList(this.page, this.pageSize).subscribe({
      next: (response) => {
        this.saleSystemList = response.salesSystemInfoRespLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  getSaleSystemNameForDealer() {
    this.loading = true
    this.service.getSaleSystemName().subscribe({
      next: (response) => {
        if (response.salesSystemName !== "null") {
          this.getSaleSystemInfo(response.salesSystemName)
        }
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  getSaleSystemInfo(name: any) {
    this.loading = true
    this.service.getSaleSystemInfo(name).subscribe({
      next: (response) => {
        this.saleSystemList.push(response)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  createSaleSystem() {
    this.isUploading = true
    var name = this.createSaleSystemForm.get('name')?.value
    var description = this.createSaleSystemForm.get('description')?.value
    var file = this.selectedFile

    this.service.getCreateSaleSystem(file, name, description).subscribe({
      next(value) {
        $('#createSaleSystemModal').modal('hide');
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  openPDF(data: any) {
    this.router.navigate(['/nav/pdf', 'SaleSystem', data.name, data.fileName])
  }

  openUpdateModel(data: any) {
    this.displayStyle = 'block'
    this.saleSystemName = data.name
    this.updateDes = data.description
  }

  closeModel() {
    this.displayStyle = 'none'
  }

  updateSaleSystem() {
    this.isUploading = true
    this.service.getUpdateSaleSystem(this.saleSystemName, this.updateDes).subscribe({
      next: (response) => {
        this.displayStyle = 'none'
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  setData(data: any) {
    this.saleSystemName = data.name
  }

  updateSaleSystemFile() {
    this.isUploading = true
    this.service.getUpdateSaleSystemFile(this.saleSystemName, this.selectedFile).subscribe({
      next: (response) => {
        $('#updateSaleSystemFileModal').modal('hide');
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  removeSaleSystem(name: any) {
    this.isUploading = true
    this.service.getRemoveSaleSystem(name).subscribe({
      next: (response) => {

      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        this.refresh.refreshPage()
      },
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllSaleSystemList();
    }
  }
}

