import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class StoreData {

  private secretKey = 'drapp-upg-key';
  private roleKey = 'drapp-role-key'
  private isDealerKey = 'drapp-isDealer-key'
  private authKey = 'drapp-auth-key'
  private loginNameKey = 'drapp-login-key'
  private rebateTypeKey = 'drapp-rebate-key'

  setLoginName(login: any) {
    const encryptedToken = CryptoJS.AES.encrypt(login, this.secretKey).toString();
    sessionStorage.setItem(this.loginNameKey, encryptedToken);
  }

  getLoginName(): string {
    const encryptedData = sessionStorage.getItem(this.loginNameKey);
    const decryptedDataString = CryptoJS.AES.decrypt(encryptedData!, this.secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedDataString
  }

  setRole(role: any) {
    const encryptedToken = CryptoJS.AES.encrypt(role, this.secretKey).toString();
    sessionStorage.setItem(this.roleKey, encryptedToken);
  }

  getRole(): string {
    const encryptedData = sessionStorage.getItem(this.roleKey);
    const decryptedDataString = CryptoJS.AES.decrypt(encryptedData!, this.secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedDataString
  }

  setIsDealer(isDealer: boolean) {
    const isDealerString = isDealer.toString();
    const encryptedToken = CryptoJS.AES.encrypt(isDealerString, this.secretKey).toString();
    sessionStorage.setItem(this.isDealerKey, encryptedToken);
  }

  getIsDealer(): boolean {
    const encryptedData = sessionStorage.getItem(this.isDealerKey);
    if(encryptedData){
      const decryptedDataString = CryptoJS.AES.decrypt(encryptedData!, this.secretKey).toString(CryptoJS.enc.Utf8);
      return Boolean(decryptedDataString)
    }else{
      return false
    }
  }

  setRebateType(type: any) {
    const encryptedToken = CryptoJS.AES.encrypt(type, this.secretKey).toString();
    sessionStorage.setItem(this.rebateTypeKey, encryptedToken);
  }

  getRebateType(): string {
    const encryptedData = sessionStorage.getItem(this.rebateTypeKey);
    const decryptedDataString = CryptoJS.AES.decrypt(encryptedData!, this.secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedDataString
  }

  setAuth(auth: any) {
    const encryptedToken = CryptoJS.AES.encrypt(auth, this.secretKey).toString();
    sessionStorage.setItem(this.authKey, encryptedToken);
  }

  getAuth(): string {
    const encryptedData = sessionStorage.getItem(this.authKey);
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
      const decryptedDataString = decryptedData.toString(CryptoJS.enc.Utf8);
      return decryptedDataString;
    } else {
      return ''
    }
  }
}
