import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StoreData } from "../utils/store_data";
import { TokenRefresh } from "./tokenRefresh_service";

@Injectable({
  providedIn: 'root'
})
export class DealerApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh,
    private route: Router) { }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getAllDealerList(page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/listdealerinfo?page=' + page + '&pageSize=' + pageSize, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/ADMIN
  getCreateDealer(body: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/registration/new', body, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/ADMIN
  getRegisterDealer(loginName: String, body: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/new?loginName=' + loginName, body, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN. ADMIN, DEALER ITSELF
  getDealerInfoByName(loginName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/dealerinfo?loginName=' + loginName, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getUpdateDealerInfoByName(loginName: any, body: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/update?loginName=' + loginName, body, httpOptions)

  }

   //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
   getNameList(): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/listdealerloginname', httpOptions)

  }

  //uploadMode = ALL & ADD (ALL is refresh the db and ADD is append)
  //PERMISSION=SUPER_ADMIN. ADMIN
  getUploadCSVDealer(type: any, file: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/uploadcsv?uploadMode=' + type, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getUploadCSVDealerAddBrandSaleSystem(file: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/upload/brands/salessystemcsv', formData, { headers, withCredentials: true })

  }

  //PERMISSION= SUPER_ADMIN
  getDownloadFile(name: string): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/dealer/registration/downloadcsv?fileName=' + name, { headers, withCredentials: true, responseType: 'text' })

  }


  //PERMISSION=SUPER_ADMIN. ADMIN
  getAddSaleSystem(loginName: any, saleSystemName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/salessystem/addupdate?loginName=' + loginName + '&salesSystemName=' + saleSystemName, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getRemoveSaleSystem(loginName: any, saleSystemName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/salessystem/remove?loginName=' + loginName, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getAddBrand(loginName: any, brandName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/brand/add?loginName=' + loginName + '&brandName=' + brandName, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getBrandsListByDealerName(loginName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/brandnames?loginName=' + loginName, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN. ADMIN
  getRemoveBrand(loginName: any, brandName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/dealer/registration/brand/remove?loginName=' + loginName + '&brandName=' + brandName, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN
  getUpdateVerifyPhone(loginName: any, phone: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/registration/update/verifyphone?loginName=' + loginName + '&newVerifyPhone=' + phone, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN
  getEnabled(loginName: any): Observable<any> {

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post(environment.baseUrl + 'api/drapp/user/registration/enabled?loginName=' + loginName, null, { headers, withCredentials: true, responseType: 'text' })

  }

  //PERMISSION=SUPER_ADMIN
  getDisabled(loginName: any): Observable<any> {

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post(environment.baseUrl + 'api/drapp/user/registration/disabled?loginName=' + loginName, null, { headers, withCredentials: true, responseType: 'text' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
