import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminInfoComponent } from './components/admin/admin-info/admin-info.component';
import { AdminComponent } from './components/admin/adminList/admin.component';
import { BrandComponent } from './components/brand/brand.component';
import { CreditNoteComponent } from './components/credit-note/credit-note.component';
import { DealerCreateComponent } from './components/dealer/dealer-create/dealer-create.component';
import { DealerInfoComponent } from './components/dealer/dealer-info/dealer-info.component';
import { DealerlistComponent } from './components/dealer/dealerlist/dealerlist.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { InvoiceComponent } from './components/invoice/invoice-list/invoice.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/nav/nav.component';
import { PdfViewerV2Component } from './components/pdf-viewer-v2/pdf-viewer-v2.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PremierPointListComponent } from './components/premier-point/premier-point-list/premier-point-list.component';
import { PriceListPdfComponent } from './components/priceList/price-list-pdf/price-list-pdf.component';
import { PriceListComponent } from './components/priceList/price-list/price-list.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PurchasingPowerAmountInfoComponent } from './components/purchasing-power/purchasing-power-amount-info/purchasing-power-amount-info.component';
import { PurchasingPowerChartComponent } from './components/purchasing-power/purchasing-power-chart/purchasing-power-chart.component';
import { PurchasingPowerDealerComponent } from './components/purchasing-power/purchasing-power-dealer/purchasing-power-dealer.component';
import { PurchasingPowerListComponent } from './components/purchasing-power/purchasing-power-list/purchasing-power-list.component';
import { QuaterComponent } from './components/quater/quater.component';
import { RebateComponent } from './components/rebate/rebate.component';
import { SaleSystemComponent } from './components/sale-system/sale-system.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent, },
  {
    path: 'nav', component: NavComponent, children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, },
      { path: 'profile', component: ProfileComponent, },
      { path: 'admin', component: AdminComponent, },
      { path: 'adminInfo/:loginName', component: AdminInfoComponent, },
      { path: 'dealer', component: DealerlistComponent, },
      { path: 'dealerInfo/:loginName', component: DealerInfoComponent, },
      { path: 'createDealer/:isCreate', component: DealerCreateComponent, },
      { path: 'brand', component: BrandComponent, },
      { path: 'sale', component: SaleSystemComponent, },
      { path: 'pdf/:type/:pdfName/:pdfFileName', component: PdfViewerComponent, },
      { path: 'rebate', component: RebateComponent, },
      { path: 'creditNotes', component: CreditNoteComponent, },
      { path: 'quarter', component: QuaterComponent, },
      { path: 'invoice', component: InvoiceComponent, },
      { path: 'invoice/:loginName', component: InvoiceComponent, },
      { path: 'priceList', component: PriceListComponent, },
      { path: 'priceListPdf', component: PriceListPdfComponent, },
      { path: 'purchasingChart/:jsonData/:shopName', component: PurchasingPowerChartComponent, },
      { path: 'purchasingList', component: PurchasingPowerListComponent, },
      { path: 'purchasingChartDealer', component: PurchasingPowerDealerComponent, },
      { path: 'purchasingAmountInfo/:quarterName', component: PurchasingPowerAmountInfoComponent, },
      { path: 'faq', component: FaqComponent, },
      { path: 'imageView/:type/:imgName/:imgFileName', component: ImageViewerComponent, },
      { path: 'pdfViewerV2/:id', component: PdfViewerV2Component, },
      { path: 'premier', component: PremierPointListComponent, },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
