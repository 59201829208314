<div class="d-flex">
  <div>
    <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#uploadCSVModal"
      *ngIf="isUploadAccess">Upload CSV
    </button>
  </div>
  <div class="mr-auto pl-3">
    <button type="button" class="btn btn-primary" (click)="createDealer()" *ngIf="isCreateAccess">
      Create Dealer
    </button>
  </div>
  <div class="pl-3">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Shop Or Company Name" [ngbTypeahead]="searchList"
        [(ngModel)]="searchName">
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" (click)="searchDealerByName()" [disabled]="!searchName">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-danger" type="button" (click)="clearSearchName()" [disabled]="!searchName">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="pl-3">
    <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#downloadCSVModal"
      *ngIf="isDownloadAccess">Download CSV
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>SrNo</th>
        <th>UPG Dealer ID</th>
        <th>Login Name</th>
        <th>Person Name</th>
        <th>Shop Or Company Name</th>
        <th>Verify Phone</th>
        <th>Viber Phone</th>
        <th>Email</th>
        <th class="w-auto" *ngIf="isInvoiceAccess">InvoiceList</th>
        <th class="w-auto">Login Enabled</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dealerList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal
        }" (click)="dealerInfo(data.dealerLoginName)">
        <td>{{data.srNo}}</td>
        <td>{{data.sapDealerId}}</td>
        <td>{{data.dealerLoginName}}</td>
        <td>{{data.contactPersonName}}</td>
        <td>{{data.shopCompanyName}}</td>
        <td>{{data.dealerVerifyPhone}}</td>
        <td>{{data.viberPhone}}</td>
        <td>{{data.email}}</td>
        <td *ngIf="isInvoiceAccess"><button type="button" class="btn btn-link"
            (click)="goToInvoice(data.dealerLoginName)">Invoice</button></td>
        <td>
          <i class="fas fa-check-circle fa-lg text-success" *ngIf="data.enabled === 'true' || data.enabled === true"></i>
          <i class="fas fa-times-circle fa-lg text-danger" *ngIf="data.enabled !== 'true' && data.enabled !== true"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please Enter File Name</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()" [disabled]="!downloadFileName">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Upload CSV Model -->
<div class="modal" id="uploadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Dealer Upload CSV File</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="uplaodDealerCSVForm">
          <div class="mb-3">
            <label class="form-label">Select Type</label>
            <div class="form-control">
              <div class="form-check form-check-inline" *ngIf="isCreateAccess">
                <input class="form-check-input" type="radio" name="type" value="ADD" formControlName="type">
                <label class="form-check-label">Append</label>
              </div>
              <div class="form-check form-check-inline pl-3" *ngIf="isCreateAccess">
                <input class="form-check-input" type="radio" name="type" value="ALL" formControlName="type">
                <label class="form-check-label">Refresh</label>
              </div>
              <div class="form-check form-check-inline pl-3" *ngIf="isUploadBrandSaleAccess">
                <input class="form-check-input" type="radio" name="type" value="AddBrandSale" formControlName="type">
                <label class="form-check-label">Add Brand & SaleSystem</label>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Select File</label>
            <input type="file" class="file-input form-control" enctype="multipart/form-data" accept=".csv"
              formControlName="file" (change)="onFileSelected($event)">
          </div>
        </form>
        <div *ngIf="isUploadError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="uploadFile()"
          [disabled]="!uplaodDealerCSVForm.valid || isUploading">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
