import { Injectable } from "@angular/core";
import { StoreData } from "./store_data";

@Injectable({
  providedIn: 'root'
})
export class CheckAccessRoles {

  constructor(private store: StoreData) {
  }

  check(rolesList: any): boolean {
    return rolesList.includes(this.store.getRole());
  }
}
