import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from "rxjs";
import { StoreData } from 'src/app/utils/store_data';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TokenRefresh {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private router: Router) {
  }

  refreshTokenApiCall(): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.store.getAuth(),
    });

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/refreshtoken', null, { headers, withCredentials: true })

  }

}
