<div>
  <h5>Frequently asked questions - အမေးများသောမေးခွန်းများ</h5>
  <div class="mt-3">
    <div class="card mt-3 rounded-3" *ngFor="let faq of faqsList">
      <div class="card-header">{{faq.ques}}</div>
      <div class="collapse show">
        <div class="card-body">{{faq.answer}}</div>
      </div>
    </div>
  </div>
</div>
