<div class="container" *ngIf="false">
  <form class="centered" [formGroup]="loginform" (ngSubmit)="submitAdminLogin()">
    <div class="text-center mb-4">
      <h2 class="mb-3"> Welcome UPG Dealer</h2>
      <img src="/assets/appicon.png" class="mx-auto d-block rounded mb-3" alt="UPG logo" width="100" height="100" />
    </div>
    <p></p>

    <div class="input-group mb-3">
      <span class="input-group-text"><i class="bi bi-person-circle"></i></span>
      <input type="text" class="form-control" placeholder="Username" formControlName="username" />
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text"><i class="bi bi-key-fill"></i></span>
      <input [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password"
        formControlName="password" />
      <span class="input-group-text">
        <i type="button" [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
          (click)="showPassword = !showPassword"></i>
      </span>
    </div>

    <div class="d-grid gap-2">
      <button type="submit" class="btn btn-primary" [disabled]="!loginform.valid" *ngIf="!isLoading">
        Login
      </button>

      <button class="btn btn-primary" type="button" disabled *ngIf="isLoading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="visually-hidden">Loading...</span>
      </button>
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-link" (click)="openPopup()">Forgot Password</button>
    </div>

    <p></p>
    <div *ngIf="isLoginError">
      <div class="alert alert-danger" role="alert">{{ txtLoginError }}</div>
    </div>
  </form>
</div>

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-pic">
        <img src="/assets/appicon.png" alt="IMG">
      </div>

      <form class="login100-form" [formGroup]="loginform" (ngSubmit)="submitAdminLogin()">
        <div class="login100-form-title">
          <h1 class="header"> Welcome To</h1>
          <h5>{{"UPG Dealer Portal "+ version }}</h5>
        </div>

        <div class="wrap-input100">
          <input class="input100" type="text" placeholder="Username" formControlName="username">
          <!-- <span class="focus-input100"></span> -->
          <span class="symbol-input100">
            <i class="fa fa-light fa-user"></i>
          </span>
        </div>

        <div class="wrap-input100">
          <input class="input100" [type]="showPassword ? 'text' : 'password'" placeholder="Password"
            formControlName="password">
          <!-- <span class="focus-input100"></span> -->
          <span class="symbol-input100">
            <i class="fa fa-lock" aria-hidden="true"></i>
          </span>
          <span class="toggle-password">
            <i type="button" [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
              (click)="showPassword = !showPassword"></i>
          </span>
        </div>

        <div class="text-right">
          <button type="button" class="btn btn-link text-black" (click)="openPopup()">Forgot Password</button>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" [disabled]="!loginform.valid" *ngIf="!isLoading">
            Login
          </button>
          <button class="login100-form-btn" type="button" disabled *ngIf="isLoading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </button>
        </div>

        <div class="mt-3" *ngIf="isLoginError">
          <div class="alert alert-danger" role="alert">{{ txtLoginError }}</div>
        </div>
      </form>

    </div>

  </div>
</div>

<!--OTP Modal -->
<div class="modal" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Please OTP Code</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input class="form-control" style="width: 100%" type="text" placeholder="Enter OTP Code"
            [(ngModel)]="otpCode" />
        </div>
        <div *ngIf="isOtpError" class="mb-3">
          <div class="alert alert-danger" role="alert">{{ txtLoginError }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closePopup()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!otpCode">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!--Change Password Modal -->
<div class="modal" tabindex="-1" aria-hidden="true" [ngStyle]="{ display: changePasswordDisplayStyle }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change New Password</h5>
        </div>
        <div class="modal-body">
          <form [formGroup]="changePasswordForm">
            <div class="mb-3">
              <label class="form-label">New Password</label>
              <div class="input-group">
                <input [type]="showNewPassword ? 'text' : 'password'" class="form-control"
                  formControlName="newPassword" />
                <span class="input-group-text">
                  <i type="button" [ngClass]="showNewPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
                    (click)="showNewPassword = !showNewPassword"></i>
                </span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Confirm Password</label>
              <div class="input-group">
                <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control"
                  formControlName="confirmPassword" />
                <span class="input-group-text">
                  <i type="button" [ngClass]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
                    (click)="showConfirmPassword = !showConfirmPassword"></i>
                </span>
              </div>
            </div>
          </form>
          <div class="mb-3" *ngIf="isUpdatePasswordError">
            <div class="alert alert-danger" role="alert">{{ txtLoginError }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closePopup()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="submitNewPassword()"
          [disabled]="!changePasswordForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
