import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RecordBreakingApiService } from 'src/app/services/record_breaking_service';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';

@Component({
  selector: 'app-purchasing-power-dealer',
  imports: [
    CommonModule,
    RouterModule,
    NgxChartsModule
  ],
  templateUrl: './purchasing-power-dealer.component.html',
  styleUrls: ['./purchasing-power-dealer.component.css']
})
export class PurchasingPowerDealerComponent {

  view: any;
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showLabels = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  showDataLabel = false
  xAxisLabel = 'Brand Names';
  yAxisLabel = 'Percentage';
  legendTitle = " "
  colorScheme: any = {
    domain: ['#9370DB', '#87CEFA']
  };
  xAxisTickFormatting = (value: any) => {
    return value.toString();
  };

  public multi: any = [];
  data: any
  show: any = false
  loading: any = true
  pastQuarterName: any
  presentQuarterName: any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private refresh: Refrsh,
    private service: RecordBreakingApiService,
    private store: StoreData
  ) {

  }

  ngOnInit(): void {
    this.getPercentInfo()
  }

  getPercentInfo() {
    this.service.getPurchaseInfo(this.store.getLoginName()).subscribe({
      next: (response) => {
        //this.router.navigate(['/nav/purchasingChart', JSON.stringify(response)]);

        this.data = response
        this.data.sort((a: { brandName: string; }, b: { brandName: string; }) => {
          const brandNameA = a.brandName.toLowerCase();
          const brandNameB = b.brandName.toLowerCase();
          return brandNameA.localeCompare(brandNameB);
        });

        this.pastQuarterName = this.data[0].pastQuarterName
        this.presentQuarterName = this.data[0].presentQuarterName
        for (const item of this.data) {
          const series = [
            { name: this.pastQuarterName, value: item.pastQuarterPurchase === '0' ? 0 : 100 },
            { name: this.presentQuarterName, value: parseInt(item.recordBreakingPercent) }
          ];
          this.multi.push({
            name: item.brandName,
            pastAmount: parseInt(item.pastQuarterPurchase),
            presentAmount: parseInt(item.presentQuarterPurchase),
            percent: parseFloat(item.recordBreakingPercent),
            series
          });
        }
      },
      error: (e) => {
        if (e) {
          if (e.includes('Active present quarter name not found in db')) {
            alert('Purchasing data not found for dealer')
          } else {
            alert(e)
          }
          this.loading = false
        }
      },
      complete: () => {
        this.show = true
      },
    })
  }
}
