import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AdminApiService } from 'src/app/services/admin_service';
import { Refrsh } from 'src/app/utils/refresh';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { CommonModule } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-admin-info',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './admin-info.component.html',
  styleUrls: ['./admin-info.component.css']
})
export class AdminInfoComponent {

  loginName = ''
  dataInfo: any = ''
  isVPhoneError: boolean = false;
  isRoleError: boolean = false;
  isInfoError: boolean = false;
  errorMessage: any = '';
  newPassword: any;
  newVerifyPhone: any = '959';
  newLoginName: any;
  isEnabled: boolean = true
  updateInfoForm: FormGroup

  //adminTypes = ['SAP_UPLOAD_ADMIN', 'ADMIN', 'PRICELIST_UPLOAD_ADMIN', 'MDOFFICE_UPLOAD_ADMIN', 'FINANCE_UPLOAD_ADMIN']
  adminTypes = Object.values(UserLoginRole).filter(role => !['DEALER', 'SUPER_ADMIN', 'SAP_UPLOAD_ADMIN'].includes(role))
  isUploading: boolean = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: AdminApiService,
    private refresh: Refrsh,
    private fb: FormBuilder
  ) {
    this.updateInfoForm = this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      department: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.loginName = String(routeParams.get('loginName'));
    this.getInfoByLoginNmae(this.loginName)
  }

  getInfoByLoginNmae(loginName: String) {
    this.service.getAdminInfoByName(loginName).subscribe({
      next: (response) => {
        this.dataInfo = response
        this.isEnabled = response.enabled

        this.setData(response)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
      },
    })
  }

  updateVerifyPhone() {
    this.isUploading = true
    this.service.getUpdateVerifyPhone(this.loginName, this.newVerifyPhone).subscribe({
      next(value) {
        alert("Success")
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isVPhoneError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        $('#updateVerifyPhoneModel').modal('hide');
        this.refresh.refreshPage()
      },
    })
  }

  updateInfo() {
    this.isUploading = true

    const requestBody = {
      name: this.updateInfoForm.get('name')?.value,
      title: this.updateInfoForm.get('title')?.value,
      department: this.updateInfoForm.get('department')?.value,
      phone: this.updateInfoForm.get('phone')?.value,
      email: this.updateInfoForm.get('email')?.value,
    };
    this.service.getUpdateAdminInfo(this.loginName, JSON.stringify(requestBody)).subscribe({
      next: (value) => {
        alert("Success")
        this.loginName = this.newLoginName
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isInfoError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        $('#updateInfoModel').modal('hide');
        this.refresh.refreshPage()
      },
    })
  }

  enabled() {
    this.service.getEnabled(this.loginName).subscribe({
      next(value) {
        alert("Success")
      },
      error: (e) => {
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.isEnabled = false
        this.refresh.refreshPage()
      },
    })
  }

  disabled() {
    this.service.getDisabled(this.loginName).subscribe({
      next(value) {
        alert("Success")
      },
      error: (e) => {
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.isEnabled = true
        this.refresh.refreshPage()
      },
    })
  }

  defaultError() {
    this.isVPhoneError = false
    this.isRoleError = false
    this.isInfoError = false
    this.errorMessage = ''
  }

  setData(value: any) {
    this.updateInfoForm.patchValue({
      name: value.name,
      title: value.title,
      department: value.department,
      phone: value.phone,
      email: value.email,
    });
  }
}
