<div class="wrapper d-flex align-items-stretch">
  <nav id="sidebar" [class.active]="sliderActive">
    <h1 class="logo m-3">
      <img src="/assets/appicon.png" class="mx-auto d-block boder border-white" alt="UPG logo" width="60"
        height="60" />
    </h1>
    <ul class="list-unstyled components mb-5">
      <li routerLinkActive="active" *ngFor="let data of link">
        <a routerLink="{{data.link}}"><span class="{{data.icon}}"></span> {{data.name}}</a>
      </li>

      <!-- <li routerLinkActive="active">
        <a routerLink="/document" data-bs-toggle="collapse" data-bs-target="#documentSubmenu" aria-expanded="false"
          class="dropdown-toggle">
          <span class="fa fa-sticky-note"></span> Document</a>
        <ul class="collapse list-unstyled" id="documentSubmenu">
          <li routerLinkActive="active">
            <a routerLink="/nav/home"> Invoice</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/nav/home">Sale</a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/nav/home">Rebates</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </nav>

  <div id="content">
    <nav class="navbar navbar-expand-lg navbar-light bg-light m-0">
      <div class="container-fluid">
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-primary" (click)="toggleSlider()">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>

          <h5 class="ml-3 my-auto">{{loginName}}</h5>
        </div>

        <div>
          <ul class="nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" routerLink="/nav/faq">FAQs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-primary" type="button" data-bs-toggle="modal"
                data-bs-target="#changePasswordModal">Change Password</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-primary" type="button" data-bs-toggle="modal"
                data-bs-target="#logoutModal">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="p-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!--Change Password Modal -->
<div class="modal" id="changePasswordModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change New Password</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="changePasswordForm">
          <div class="mb-3">
            <label class="form-label">New Password</label>
            <div class="input-group">
              <input [type]="showNewPassword ? 'text' : 'password'" class="form-control"
                formControlName="newPassword" />
              <span class="input-group-text">
                <i type="button" [ngClass]="showNewPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
                  (click)="showNewPassword = !showNewPassword"></i>
              </span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Confirm Password</label>
            <div class="input-group">
              <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control"
                formControlName="confirmPassword" />
              <span class="input-group-text">
                <i type="button" [ngClass]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
                  (click)="showConfirmPassword = !showConfirmPassword"></i>
              </span>
            </div>
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!changePasswordForm.valid"
          (click)="submitChangePassword()">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!--Log out Modal -->
<div class="modal" id="logoutModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Logout</h5>
      </div>
      <div class="modal-body">Are you sure you want to logout?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submitAdminLogout()">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">OK</span>
        </button>
      </div>
    </div>
  </div>
</div>
