export enum UserLoginRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  SAP_UPLOAD_ADMIN = 'SAP_UPLOAD_ADMIN',
  ADMIN = 'ADMIN',
  DEALER_ADMIN = 'DEALER_ADMIN',
  PRICELIST_UPLOAD_ADMIN = 'PRICELIST_UPLOAD_ADMIN',
  MDOFFICE_UPLOAD_ADMIN = 'MDOFFICE_UPLOAD_ADMIN',
  FINANCE_UPLOAD_ADMIN = 'FINANCE_UPLOAD_ADMIN',
  DEALER = 'DEALER',
}
