import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StoreData } from '../utils/store_data';
import { TokenRefresh } from './tokenRefresh_service';

@Injectable({
  providedIn: 'root'
})
export class QuaterApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh) { }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
  getQuaterList(page: any,pageSize:any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/quarter/registration/listquarterinfo?page=' + page + '&pageSize=' + pageSize, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getCreateQuater(name: any, des: any, beginDate: any, endDate: any, status: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/quarter/registration/new?name=' + name + '&description=' + des + '&beginDate=' + beginDate + '&endDate=' + endDate + '&status=' + status, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getUpdateQuater(name: any, des: any, beginDate: any, endDate: any, status: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/quarter/registration/update?name=' + name + '&description=' + des + '&beginDate=' + beginDate + '&endDate=' + endDate + '&status=' + status, null, httpOptions)

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
