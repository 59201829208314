import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SessionKeys } from '../enumeration/sessionKeys';
import { CreditNoteSession } from '../models/credit_note_session_model';
import { CreateFileName } from '../utils/create-file-name';
import { StoreData } from '../utils/store_data';
import { TokenRefresh } from './tokenRefresh_service';

@Injectable({
  providedIn: 'root'
})
export class CreditNotesApiService {


  credit_note_session: CreditNoteSession = {};

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh,
    private file_service: CreateFileName) { }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
  getCreditNotesList(loginName: any, page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()

    this.credit_note_session.searchName = loginName;
    this.credit_note_session.page = page;

    sessionStorage.setItem(SessionKeys.CREDIT_NOTE, JSON.stringify(this.credit_note_session))

    return this.http.get<any>(environment.baseUrl + 'api/drapp/creditnote/registration/listcreditnoteinfo?loginName=' + loginName + '&page=' + page + '&pageSize=' + pageSize, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
  getNameList(): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/listdealerloginname', httpOptions)

  }


  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getCreateCreditNotes(file: any, loginName: any, des: any, date: any): Observable<any> {

    //var fileName = file.name.split("_")[0] + '.jpg'
    //var fileName = this.file_service.createFileName(file.name, date)
    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/creditnote/registration/upload?loginName=' + loginName + '&description=' + des + '&effectiveDate=' + date, formData, { headers, withCredentials: true })

  }

  getUploadZipFileRebate(file: any, date: string): Observable<any> {
    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/creditnote/registration/zipfileupload?effectiveDate=' + date, formData, { headers, withCredentials: true })

  }

  getUploadZipFileRebateStatus() {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/creditnote/registration/zipfileupload/status', httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getUpdateCreditNotes(name: any, des: any, date: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/creditnote/registration/update?creditNoteName=' + name + '&description=' + des + '&effectiveDate=' + date, null, httpOptions)

  }

  getUpdateCreditNoteFile(name: string, file: File) {
    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/creditnote/registration/updatefile?creditNoteName=' + name, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getRemoveCreditNotes(name: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/creditnote/registration/remove?creditNoteName=' + name, null, httpOptions)

  }

  getDownloadFile(name: string): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/creditnote/registration/downloadcreditnote?creditNoteName=' + name, { headers, withCredentials: true, responseType: 'blob' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
