<div *ngIf="show">
  <ngx-extended-pdf-viewer [src]="src" [textLayer]="true" [showPresentationModeButton]="true"
    filenameForDownload="{{fileName + '.pdf'}}">
  </ngx-extended-pdf-viewer>
</div>

<div class="d-flex flex-column align-items-center justify-content-center vh-100" *ngIf="!show">
  <ng-container *ngIf="loading; else notLoading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p class="mt-3">Please wait, loading data...</p>
  </ng-container>
  <ng-template #notLoading>
    <!-- <p class="mt-3 fs-5">Please wait while data is uploading...</p> -->
    <!-- <p class="fs-6 fw-bold">Uploading Time : 3 Hours</p> -->
    <p class="fs-6 fw-bold">Your file is still being processed. Please wait for it to be ready.</p>
  </ng-template>
</div>
