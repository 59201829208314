import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'date-fns';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any): string {
    if (value.includes('/')) {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
      return value ? format(parsedDate, 'MM/dd/yyyy') : '';
    } else {
      //const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
      const date = new Date(value)
      return value ? format(date, 'dd/MM/yyyy') : '';
    }
  }

  convertYYYMMDD(value: any): string {
    if (value.includes('/')) {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
      return format(parsedDate, 'yyyy-MM-dd');
    } else {
      const date = new Date(value)
      return format(date, 'yyyy-MM-dd');
    }

  }

  convertDDMMYYYY(value: any): string {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
    return format(parsedDate, 'dd/MM/yyyy');
  }

  convertMMDDYYYY(value: any): string {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
    return format(parsedDate, 'MM/dd/yyyy');
  }

  convertObjectToDDMMYYYY(value: any): string {
    var day = value.day.toString().padStart(2, '0');
    var month = value.month.toString().padStart(2, '0');
    var year = value.year;
    return `${day}/${month}/${year}`;
  }

  convertDateToObject(value: any): any {
    if (value.includes('/')) {
      const [day, month, year] = value.split('/').map(Number);
      return { year, month, day };
    } else {
      const date = new Date(value);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1, // Adding 1 since NgbDatepicker month is 1-based
        day: date.getDate()
      };
    }
  }
}
