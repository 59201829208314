import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'dd/MM/yyyy'; // Set your desired format here

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const parts = value.trim().split('/');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        return { day, month, year };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? `${this.pad(date.day)}/${this.pad(date.month)}/${date.year}` : '';
  }

  private pad(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }
}
