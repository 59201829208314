import { CheckAccessRoles } from './../../../utils/check-access-roles';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { PriceListApiService } from 'src/app/services/pricelist_service';
import { Refrsh } from 'src/app/utils/refresh';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { StoreData } from 'src/app/utils/store_data';
import { PageSize } from 'src/app/enumeration/pageSize';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
declare var $: any

@Component({
  selector: 'app-price-list-pdf',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    DateFormatPipe
  ],
  templateUrl: './price-list-pdf.component.html',
  styleUrls: ['./price-list-pdf.component.css']
})
export class PriceListPdfComponent {

  loading: any = false;
  priceList: any;
  pageSize = PageSize.PRICE_LIST
  page = 0
  allTotal = 0
  createPriceListForm: FormGroup<any>;
  isError: any;
  errorMessage: any;
  displayStyle: any;
  updatePriceListForm: FormGroup<any>;
  selectedName: any;
  priceListName: any
  selectedFile: any
  isDealer = false;
  isUploading: boolean = false
  isCreateUpadateRemoveAccess: any = false

  constructor(private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private service: PriceListApiService,
    private store: StoreData,
    private dateFormat: DateFormatPipe,
    private checkAccessRoles:CheckAccessRoles) {
    this.createPriceListForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      file: ['', Validators.required],
    });
    this.updatePriceListForm = this.fb.group({
      effectiveDate: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isCreateUpadateRemoveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN,UserLoginRole.PRICELIST_UPLOAD_ADMIN])

    if (this.store.getIsDealer()) {
      this.isDealer = true
    }

    this.getAllPriceList()
  }

  getAllPriceList() {
    this.loading = true
    this.service.getPriceList().subscribe({
      next: (response) => {
        this.priceList = response.cNMdoRUploadRespLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        if (e ) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }

  createPriceList() {
    this.isUploading = true

    var name = this.createPriceListForm.get('name')?.value
    var description = this.createPriceListForm.get('description')?.value
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.createPriceListForm.get('effectiveDate')?.value)

    this.service.getCreatePriceList(this.selectedFile, name, description, date).subscribe({
      next(value) {
        $('#createPriceListModal').modal('hide');
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  openPDF(data: any) {
    this.router.navigate(['/nav/pdf', 'PriceList', data.name, data.fileName])
  }

  openUpdateModel(data: any) {
    this.displayStyle = 'block'
    this.priceListName = data.name
    this.updatePriceListForm.patchValue({
      description: data.description,
      effectiveDate: this.dateFormat.convertDateToObject(data.effectiveDate),
    })
  }

  closeModel() {
    this.displayStyle = 'none'
  }

  updatePriceList() {
    this.isUploading = true
    var description = this.updatePriceListForm.get('description')?.value
    var date = this.dateFormat.convertObjectToDDMMYYYY(this.updatePriceListForm.get('effectiveDate')?.value)

    this.service.getUpdatePriceList(this.priceListName, description, date).subscribe({
      next: (response) => {
        this.displayStyle = 'none'
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  removePriceList(name: any) {
    this.isUploading = true
    this.service.getRemovePriceList(name).subscribe({
      next: (response) => {
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        this.refresh.refreshPage()
      },
    })
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllPriceList();
    }
  }
}
