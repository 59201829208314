import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StoreData } from "../utils/store_data";
import { TokenRefresh } from "./tokenRefresh_service";

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh) { }

  //PERMISSION=SUPER_ADMIN
  getCreateAdmin(body: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/registration/new', body, httpOptions)
  }

  //PERMISSION=SUPER_ADMIN
  getRegisterAdmin(loginName: String, body: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/admin/registration/new?loginName=' + loginName, body, httpOptions)
  }

  //PERMISSION=SUPER_ADMIN
  getUpdateAdminInfo(loginName: String, body: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/admin/registration/update?loginName=' + loginName, body, httpOptions)
  }

  //PERMISSION=SUPER_ADMIN
  getListAdmin(page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/admin/registration/listadmininfo?page=' + page + '&pageSize=' + pageSize, httpOptions)
  }

  //PERMISSION=SUPER_ADMIN
  getAdminInfoByName(loginName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/admin/registration/admininfo?loginName=' + loginName, httpOptions)

  }

  //PERMISSION=ALL ADMIN EXCEPT SUPER_ADMIN
  getAdminInfoBySelf(): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/admin/registration/admininfo', httpOptions)

  }

  //PERMISSION= SUPER_ADMIN
  getDownloadFile(name: string): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/admin/registration/downloadcsv?fileName=' + name, { headers, withCredentials: true, responseType: 'text' })

  }

  //uploadMode = ALL & ADD (ALL is refresh the db and ADD is append)
  getUploadCSVAdmin(type: any, file: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/admin/registration/uploadcsv?uploadMode=' + type, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN
  getUpdateLoginName(oldName: any, newName: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/registration/update/loginname?oldLoginName=' + oldName + '&newLoginName=' + newName, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN
  getUpdateVerifyPhone(loginName: any, phone: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/registration/update/verifyphone?loginName=' + loginName + '&newVerifyPhone=' + phone, null, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN
  getEnabled(loginName: any): Observable<any> {

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post(environment.baseUrl + 'api/drapp/user/registration/enabled?loginName=' + loginName, null, { headers, withCredentials: true, responseType: 'text' })

  }

  //PERMISSION=SUPER_ADMIN
  getDisabled(loginName: any): Observable<any> {

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post(environment.baseUrl + 'api/drapp/user/registration/disabled?loginName=' + loginName, null, { headers, withCredentials: true, responseType: 'text' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }
}
