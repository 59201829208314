import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeApiService } from 'src/app/services/home_service';
import { InvoiceApiService } from 'src/app/services/invoice_service';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';

@Component({
  selector: 'app-home',
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  showMenu: any = false
  isDealer: boolean = false
  date: any

  username: string = ''
  role: string = ''
  header: string = ''
  smsbalance: string = "Check sms balance"
  isSMSCheckAccess: boolean = false

  images = [
    { src: '/assets/images/image_1.png', alt: 'Image 1' },
    { src: '/assets/images/image_2.png', alt: 'Image 2' },
    { src: '/assets/images/image_3.png', alt: 'Image 3' },
    { src: '/assets/images/image_4.png', alt: 'Image 4' },
    { src: '/assets/images/image_5.png', alt: 'Image 5' },
    { src: '/assets/images/image_6.png', alt: 'Image 6' },
    { src: '/assets/images/image_7.png', alt: 'Image 7' },
    { src: '/assets/images/image_8.png', alt: 'Image 8' },
    { src: '/assets/images/image_9.png', alt: 'Image 9' }
  ];

  getImagesForSlide(slideIndex: number): any[] {
    const startIndex = slideIndex * 3;
    return this.images.slice(startIndex, startIndex + 3);
  }

  constructor(
    private refresh: Refrsh,
    private service: InvoiceApiService,
    private store: StoreData,
    private homeService: HomeApiService,
    private checkAccessRoles:CheckAccessRoles) {
    this.date = new Date().toLocaleString()
  }

  ngOnInit(): void {
    //this.isSMSCheckAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN,UserLoginRole.ADMIN])
    if (this.store.getIsDealer()) {
      this.isDealer = true
      this.header = 'UPG Dealer Portal - Dealer'
      // this.getTotalBalanceForAll()
    } else {
      this.header = 'UPG Dealer Portal - Admin'
    }

    this.username = this.store.getLoginName()
    this.role = this.store.getRole()
  }

  // getTotalBalanceForAll() {
  //   this.service.getTotalBalance('PAID').subscribe({
  //     next: (response) => {

  //     },
  //     error: (e) => {
  //       if (e === "401") {
  //         this.refresh.refreshToken(() => this.getTotalBalanceForAll())
  //       } else {
  //         alert(e)
  //       }
  //     },
  //     complete: () => {

  //     },
  //   })
  // }

  checksmsbalance() {

    this.smsbalance = "Loading balance"

    // this.homeService.getSMSCheck().subscribe({
    //   next: (data) => {
    //     this.smsbalance = "SMS balance " + String(data)
    //   },
    //   error: (e) => {
    //     if (e === "401") {
    //       this.refresh.refreshToken(() => this.checksmsbalance())
    //     } else {
    //       alert(e)
    //     }
    //   },
    //   complete: () => {

    //   },
    // })
  }
}
