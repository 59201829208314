<div class="d-flex">
  <div class="mr-auto">
    <!-- <button class="btn btn-primary " (click)="uploadFile()" [disabled]="uploadProgress || !fileName">Upload CSV
    </button> -->
    <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#uploadCSVModal">Upload CSV
    </button>
  </div>
  <div class="pl-3">
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createAdminModal">
      Create Admin
    </button>
  </div>
  <div class="pl-3">
    <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#downloadCSVModal">Download CSV
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>Sr No</th>
        <th>Login Name</th>
        <th>Name</th>
        <th>Title</th>
        <th>Department</th>
        <th>Verify Phone</th>
        <th>Email</th>
        <th>Admin Role</th>
        <th>Enabled</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal
        }" (click)="adminInfo(data.adminLoginName)">
        <td>{{ data.srNo }}</td>
        <td>{{ data.adminLoginName }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.title }}</td>
        <td>{{ data.department }}</td>
        <td>{{ data.adminVerifyPhone }}</td>
        <td>{{ data.email }}</td>
        <td>{{ data.adminRoleSet }}</td>
        <td>
          <i class="fas fa-check-circle fa-lg text-success" *ngIf="data.enabled"></i>
          <i class="fas fa-times-circle fa-lg text-danger" *ngIf="!data.enabled"></i>
        </td>

        <!-- <td>{{ data.enabled }}</td> -->
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- Create Admin Modal -->
<div class="modal" id="createAdminModal" tabindex="-1" aria-labelledby="createAdminModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="createAdminModalLabel">Create Admin Login User</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="createAdminForm" *ngIf="isCreateAdmin">
          <div class="mb-3">
            <label class="form-label">Login Name</label>
            <input type="text" class="form-control" formControlName="loginName">
          </div>
          <div class="mb-3">
            <label class="form-label">Password</label>
            <input type="password" class="form-control" formControlName="password">
          </div>
          <div class="mb-3">
            <label class="form-label">Verify Phone</label>
            <input type="tel" class="form-control" formControlName="verifyPhone">
          </div>
          <div class="mb-3">
            <label class="form-label">Role</label>
            <app-multi-select-checkbox [list]="list" (shareCheckedList)="shareCheckedList($event)"
              (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
            </app-multi-select-checkbox>
          </div>
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" formControlName="enabled">
            <label class="form-check-label">Enabled</label>
          </div>
        </form>
        <form [formGroup]="registerAdminForm" *ngIf="!isCreateAdmin">
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" formControlName="email" placeholder="a@gmail.com">
          </div>
          <div class="mb-3">
            <label class="form-label">Phone</label>
            <input type="tel" class="form-control" formControlName="phone">
          </div>
          <div class="mb-3">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" formControlName="title">
          </div>
          <div class="mb-3">
            <label class="form-label">Department</label>
            <input type="text" class="form-control" formControlName="department">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="createAdmin()"
          [disabled]="!createAdminForm.valid || role.length<=0 || isUploading" *ngIf="isCreateAdmin">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Next</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="registerAdmin()" [disabled]="!registerAdminForm.valid"
          *ngIf="!isCreateAdmin">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Admin Download CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Enter file name" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()"
          [disabled]="!downloadFileName || isUploading">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Upload CSV Model -->
<div class="modal" id="uploadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Admin Upload CSV File</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="uplaodAdminCSVForm">
          <div class="mb-3">
            <label class="form-label">Select Type</label>
            <div class="form-control">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="type" value="ADD" formControlName="type">
                <label class="form-check-label">Append</label>
              </div>
              <div class="form-check form-check-inline pl-3">
                <input class="form-check-input" type="radio" name="type" value="ALL" formControlName="type">
                <label class="form-check-label">Refresh</label>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Select File</label>
            <input type="file" class="file-input form-control" enctype="multipart/form-data" accept=".csv"
              formControlName="file" (change)="onFileSelected($event)">
          </div>
        </form>
        <div *ngIf="isUploadError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="uploadFile()"
          [disabled]="!uplaodAdminCSVForm.valid || isUploading">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
