import { StoreData } from './../../../utils/store_data';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { BrandApiService } from 'src/app/services/brand_service';
import { DealerApiService } from 'src/app/services/dealer_service';
import { SaleSystemApiService } from 'src/app/services/sale_system_service';
import { Refrsh } from 'src/app/utils/refresh';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { PageSize } from 'src/app/enumeration/pageSize';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
declare var $: any

@Component({
  selector: 'app-dealer-info',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './dealer-info.component.html',
  styleUrls: ['./dealer-info.component.css']
})
export class DealerInfoComponent {

  loginName = ''
  data: any
  page: number = 0
  pageSize: number = PageSize.DEALER_LIST
  allTotal: number = 0
  pageForSaleSystem = 0
  pageSizeForSaleSystem = PageSize.SALESYSTEM_LIST
  displaySaleSystemStyle: string = 'none';
  displayAddBrandStyle: any = 'none';
  displayRemoveBrandStyle: any = 'none';
  saleSystemList: any[] = []
  saleSystemName = ''
  isSaleSystemError: any = false;
  errorMessage: any;
  addBrandName: any = '';
  addBrandList: any[] = [];
  removeBrandName: any = '';
  removeBrandList: any[] = [];
  isUploading: boolean = false
  newVerifyPhone: any = '959';
  isVPhoneError: boolean = false;
  isEnabled: boolean = true
  isUpdateInfoVerifyPhoneAccess: any = false
  isAddRemoveBrandSaleSystem: boolean = false
  isEnableDisableAccess: boolean = false

  allowedRolesForUpdateInfoVerifyPhone: any = [UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN, UserLoginRole.DEALER_ADMIN]
  allowedRolesForAddRemoveBrandSaleSystem: any = [UserLoginRole.SUPER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN]
  allowedRolesForEnableDisable: any = [UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN, UserLoginRole.DEALER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: DealerApiService,
    private saleSystemService: SaleSystemApiService,
    private brandService: BrandApiService,
    private refresh: Refrsh,
    private storeData: StoreData,
    private checkAccessRoles: CheckAccessRoles
  ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.loginName = String(routeParams.get('loginName'));
    this.isUpdateInfoVerifyPhoneAccess = this.checkAccessRoles.check(this.allowedRolesForUpdateInfoVerifyPhone)
    this.isAddRemoveBrandSaleSystem = this.checkAccessRoles.check(this.allowedRolesForAddRemoveBrandSaleSystem)
    this.isEnableDisableAccess = this.checkAccessRoles.check(this.allowedRolesForEnableDisable)

    this.getDealerInfo()
  }

  getDealerInfo() {
    this.service.getDealerInfoByName(this.loginName).subscribe({
      next: (response) => {
        this.data = response
        if (response.enabled === 'false') {
          this.isEnabled = false
        }

        this.saleSystemName = response.salesSystemName
        response.brandSet.map((value: any) => {
          this.removeBrandList.push(value)
        })
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    })
  }

  openPopupSaleSystem() {
    this.saleSystemService.getSaleSystemList(this.pageForSaleSystem, this.pageSizeForSaleSystem).subscribe({
      next: (response) => {
        this.saleSystemList = []
        response.salesSystemInfoRespLst.map((value: any) => {
          this.saleSystemList.push(value.name)
        })
      },
      error: (e) => {
        if (e) {
          alert(e)
        }

      },
      complete: () => {
        this.displaySaleSystemStyle = 'block'
      },
    })
  }

  addSaleSystem() {
    this.isUploading = true
    this.service.getAddSaleSystem(this.loginName, this.saleSystemName).subscribe({
      next: (response) => {
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isSaleSystemError = true
          this.errorMessage = e
        }

      },
      complete: () => {
        this.isUploading = false
        this.isSaleSystemError = false
        this.closePopup()
        this.refresh.refreshPage()
      },
    })
  }

  removeSaleSystem() {
    this.isUploading = true
    this.service.getRemoveSaleSystem(this.loginName, this.saleSystemList).subscribe({
      next: (response) => {
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isSaleSystemError = true
          this.errorMessage = e
        }

      },
      complete: () => {
        this.isUploading = false
        this.isSaleSystemError = false
        this.closePopup()
        this.refresh.refreshPage()
      },
    })
  }

  openPopupAddBrand() {
    this.brandService.getBrandList(this.page, this.pageSize).subscribe({
      next: (response) => {
        this.addBrandList = []
        response.brandInfoLst.map((value: any) => {
          this.addBrandList.push(value.name)
          //remove
          this.addBrandList = this.addBrandList.filter(item => !this.removeBrandList.includes(item));
        })
      },
      error: (e) => {
        if (e) {
          alert(e)
        }

      },
      complete: () => {
        this.displayAddBrandStyle = 'block'
      },
    })
  }

  openPopupRemoveBrand() {
    this.displayRemoveBrandStyle = 'block'

    // this.service.getBrandsListByDealerName(this.loginName).subscribe({
    //   next: (response) => {
    //     if(response){
    //       response.map((value: any) => {
    //         this.removeBrandList.push(value.name)
    //       })
    //     }
    //   },
    //   error: (e) => {
    //     if (e === "401") {
    //       this.refresh.refreshToken(() => this.openPopupRemoveBrand())
    //     } else {
    //       alert(e)
    //     }

    //   },
    //   complete: () => {
    //     this.displayRemoveBrandStyle = 'block'
    //   },
    // })
  }

  addBrand() {
    this.isUploading = true
    this.service.getAddBrand(this.loginName, this.addBrandName).subscribe({
      next: (response) => {
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isSaleSystemError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isSaleSystemError = false
        this.closePopup()
        this.refresh.refreshPage()
      },
    })
  }

  removeBrand() {
    this.isUploading = true
    this.service.getRemoveBrand(this.loginName, this.removeBrandName).subscribe({
      next: (response) => {
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isSaleSystemError = true
          this.errorMessage = e
        }

      },
      complete: () => {
        this.isUploading = false
        this.isSaleSystemError = false
        this.closePopup()
        this.refresh.refreshPage()
      },
    })
  }

  closePopup() {
    this.displaySaleSystemStyle = 'none'
    this.displayAddBrandStyle = 'none'
    this.displayRemoveBrandStyle = 'none'
  }

  update() {
    this.router.navigate(['/nav/createDealer', this.loginName])
  }

  updateVerifyPhone() {
    this.isUploading = true
    this.service.getUpdateVerifyPhone(this.loginName, this.newVerifyPhone).subscribe({
      next(value) {
        alert("Success")
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isVPhoneError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        $('#updateVerifyPhoneModel').modal('hide');
        this.refresh.refreshPage()
      },
    })
  }

  enabled() {
    this.service.getEnabled(this.loginName).subscribe({
      next(value) {
        alert("Success")
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isEnabled = false
        this.refresh.refreshPage()
      },
    })
  }

  disabled() {
    this.service.getDisabled(this.loginName).subscribe({
      next(value) {
        alert("Success")
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isEnabled = true
        this.refresh.refreshPage()
      },
    })
  }
}


