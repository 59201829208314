import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StoreData } from '../utils/store_data';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private datePipe: DatePipe) {

  }

  //PERMISSION=SUPER_ADMIN/ADMIN/LOGIN DEALER
  getInvoiceList(page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()
    var loginName = 'ALL'
    if (this.store.getIsDealer()) {
      loginName = this.store.getLoginName()
    }

    return this.http.get<any>(environment.baseUrl + 'api/drapp/invoice/listinvoiceinfo?dealerLoginName=' + loginName + '&page=' + page + '&pageSize=' + pageSize, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/ADMIN/LOGIN DEALER
  //DD/MM/YYYY
  getInvoiceListByFilter(name: any, type: any, orderBy: any, startDate: any, endDate: any, page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()
    var api = ''
    // const start = this.datePipe.transform(startDate, 'dd/MM/yyyy');
    // const end = this.datePipe.transform(endDate, 'dd/MM/yyyy');
    const start = startDate;
    const end = endDate;
    var loginName = 'ALL'
    if (name) {
      loginName = name
    }

    if (this.store.getIsDealer()) {
      if (startDate) {
        api = 'api/drapp/invoice/listinvoiceinfo?invoiceType=' + type + '&orderBy=' + orderBy + '&startDate=' + start + '&endDate=' + end + '&page=' + page + '&pageSize=' + pageSize
      } else {
        api = 'api/drapp/invoice/listinvoiceinfo?&invoiceType=' + type + '&orderBy=' + orderBy + '&page=' + page + '&pageSize=' + pageSize
      }
    } else {
      if (startDate) {
        api = 'api/drapp/invoice/listinvoiceinfo?dealerLoginName=' + loginName + '&invoiceType=' + type + '&orderBy=' + orderBy + '&startDate=' + start + '&endDate=' + end + '&page=' + page + '&pageSize=' + pageSize
      } else {
        api = 'api/drapp/invoice/listinvoiceinfo?dealerLoginName=' + loginName + '&invoiceType=' + type + '&orderBy=' + orderBy + '&page=' + page + '&pageSize=' + pageSize
      }
    }

    return this.http.get<any>(environment.baseUrl + api, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/ADMIN/LOGIN DEALER
  getInvoiceInfo(id: any, dealerLoginName: any): Observable<any> {

    const httpOptions = this.getHeaders()
    var api = ''

    if (dealerLoginName) {
      api = 'api/drapp/invoice/invoiceinfo?dealerLoginName=' + dealerLoginName + '&invoiceNumber=' + id
    } else {
      api = 'api/drapp/invoice/invoiceinfo?invoiceNumber=' + id
    }

    return this.http.get<any>(environment.baseUrl + api, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
  getNameList(): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/listdealerloginname', httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/ADMIN/LOGIN DEALER
  getDownloadFile(name: string, dealerLoginName: any, type: any, startDate: any, endDate: any): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    var api = ''
    // const start = this.datePipe.transform(startDate, 'dd/MM/yyyy');
    // const end = this.datePipe.transform(endDate, 'dd/MM/yyyy');
    const start = startDate;
    const end = endDate;
    dealerLoginName = dealerLoginName ? dealerLoginName : this.store.getLoginName();
    type = type ? type : 'ALL';

    if (startDate) {
      api = 'api/drapp/invoice/downloadcsv?fileName=' + name + '&dealerLoginName=' + dealerLoginName + '&invoiceType=' + type + '&startDate=' + start + '&endDate=' + end
    } else {
      api = 'api/drapp/invoice/downloadcsv?fileName=' + name + '&dealerLoginName=' + dealerLoginName + '&invoiceType=' + type
    }

    return this.http.get(environment.baseUrl + api, { headers, withCredentials: true, responseType: 'text' })

  }

  //PERMISSION=SUPER_ADMIN/ADMIN/LOGIN DEALER
  getTotalBalance(dealerLoginName: any, type: any): Observable<any> {

    const httpOptions = this.getHeaders()

    var api = ''
    if (dealerLoginName === "null") {
      api = environment.baseUrl + 'api/drapp/invoice/totalbalance?dealerLoginName=' + this.store.getLoginName() + '&invoiceType=' + type
    } else {
      api = environment.baseUrl + 'api/drapp/invoice/totalbalance?dealerLoginName=' + dealerLoginName + '&invoiceType=' + type
    }

    return this.http.get<any>(api, httpOptions)

  }

  getDownloadPDF(id: any): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/invoice/image/download?invoiceNumber=' + id, { headers, withCredentials: true, responseType: 'blob' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }
}
