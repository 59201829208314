import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StoreData } from '../utils/store_data';
import { TokenRefresh } from './tokenRefresh_service';

@Injectable({
  providedIn: 'root'
})
export class LogoutApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh) { }

  //
  getLogout(): Observable<any> {

    const httpOptions = this.getHeaders()
    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/logout', null, httpOptions)

  }

  //PERMISSION=ALL ADMIN
  getUpdatePassword(password: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/user/registration/update/password?newPassword=' + password, null, httpOptions)

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
