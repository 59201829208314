import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { InvoiceApiService } from 'src/app/services/invoice_service';
import { Refrsh } from 'src/app/utils/refresh';

@Component({
  selector: 'app-pdf-viewer-v2',
  imports: [
    CommonModule,
    RouterModule,
    NgxExtendedPdfViewerModule
  ],
  templateUrl: './pdf-viewer-v2.component.html',
  styleUrls: ['./pdf-viewer-v2.component.css']
})
export class PdfViewerV2Component {

  loading: any = false;
  id: any = ''
  src: any = ''
  show: any = false

  constructor(
    private pdfService: NgxExtendedPdfViewerService,
    private route: ActivatedRoute,
    private invoice_api: InvoiceApiService,
    private refresh: Refrsh,
  ) {

    pdfDefaultOptions.doubleTapZoomFactor = '150%'; // The default value is '200%'
    pdfDefaultOptions.maxCanvasPixels = 4096 * 4096 * 5; // The default value is 4096 * 4096 pixels,
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.id = String(routeParams.get('id'));

    this.getInvicePDFFile()
  }

  getInvicePDFFile() {
    this.loading = true
    this.invoice_api.getDownloadPDF(this.id).subscribe({
      next: (response) => {
        this.getPdffile(response)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }

      },
      complete: () => {
        this.loading = false
        this.show = true
      },
    })
  }

  getPdffile(data: any) {
    const blob = new Blob([data], { type: 'image/pdf' });
    const url = URL.createObjectURL(blob);
    this.src = url

    // var blob = data as Blob
    // var url = window.URL.createObjectURL(blob)
    // window.open(url)

  }

}
