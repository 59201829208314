import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { BrandApiService } from 'src/app/services/brand_service';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
import { CheckAccessRoles } from './../../utils/check-access-roles';
declare var $: any

@Component({
  selector: 'app-brand',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent {

  brandList: any = []
  pageSize = PageSize.BRAND_LIST
  page = 0
  allTotal = 0
  loading = false
  createBrandForm: FormGroup<any>;
  isError = false
  errorMessage = ''
  displayStyle: any = 'none';
  updateBrandForm: FormGroup<any>;
  brandName = ''
  isUploading: boolean = false
  isCreateUpdateRemoveAccess: boolean = false

  constructor(private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private service: BrandApiService,
    private store: StoreData,
    private checkAccessRoles: CheckAccessRoles) {
    this.createBrandForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      sapBrandId: ['', Validators.required]
    });

    this.updateBrandForm = this.fb.group({
      description: ['', Validators.required],
      sapBrandId: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isCreateUpdateRemoveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])

    this.getAllBrandList()
  }

  getAllBrandList() {
    this.loading = true
    this.service.getBrandList(this.page, this.pageSize).subscribe({
      next: (response) => {
        this.brandList = response.brandInfoLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        this.loading = false
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      },
    })

  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllBrandList();
    }
  }


  createBrand() {
    this.isUploading = true
    const requestBody = {
      name: this.createBrandForm.get('name')?.value,
      description: this.createBrandForm.get('description')?.value,
      sapBrandId: this.createBrandForm.get('sapBrandId')?.value,
    };
    this.service.getCreateBrand(JSON.stringify(requestBody)).subscribe({
      next(value) {

      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        $('#createBrandModal').modal('hide');
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  openUpdateModel(data: any) {
    this.displayStyle = 'block'
    this.brandName = data.name
    this.updateBrandForm.patchValue({
      description: data.description,
      sapBrandId: data.sapBrandId,
    })
  }

  closeModel() {
    this.displayStyle = 'none'
  }

  updateBrand() {
    this.isUploading = true
    const requestBody = {
      name: this.brandName,
      description: this.updateBrandForm.get('description')?.value,
      sapBrandId: this.updateBrandForm.get('sapBrandId')?.value,
    };
    this.service.getUpdateBrand(this.brandName, requestBody).subscribe({
      next: (response) => {
        this.displayStyle = 'none'
      },
      error: (e) => {
        this.isUploading = false
        if(e){
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isError = false
        this.refresh.refreshPage()
      },
    })
  }

  removeBrand(name: any) {
    this.isUploading = true
    this.service.getRemoveBrand(name).subscribe({
      next: (response) => {

      },
      error: (e) => {
        this.isUploading = false
        if(e){
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        this.refresh.refreshPage()
      },
    })
  }


}
