<div class="row" *ngIf="isCreateUpdateRemoveAccess">
  <div class="col">
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createSaleSystemModal">
      Create
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>SrNo</th>
        <th>Name</th>
        <th>Description</th>
        <th>File Name</th>
        <th *ngIf="isCreateUpdateRemoveAccess"></th>
      </tr>
    </thead>
    <tbody class="table-sm">
      <tr *ngFor="let data of saleSystemList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal
        }; index as i">
        <td>{{data.srNo}}</td>
        <td>{{data.name}}</td>
        <td>{{data.description}}</td>
        <td><button type="button" class="btn btn-link" (click)="openPDF(data)">{{data.fileName}}</button></td>
        <td *ngIf="isCreateUpdateRemoveAccess">
          <div class="dropstart float-end">
            <!-- <button type="button" class="btn btn-primary" (click)="openUpdateModel(data)">Update</button>
            <button type="button" class="btn btn-danger ml-3 mt-1" (click)="removeSaleSystem(data.name)">
              <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              <span *ngIf="!isUploading">Remove</span>
            </button> -->
            <button type="button" class="btn btn-primary btn-sm ms-auto" data-bs-toggle="dropdown"
              aria-expanded="false">
              <span><i class="fa-regular fa-pen-to-square"></i></span>
              <span class="mx-1 fw-normal fst-italic">Edit</span>
            </button>
            <ul class="dropdown-menu">
              <li><button class="dropdown-item" type="button" (click)="setData(data)" data-bs-toggle="modal"
                  data-bs-target="#updateSaleSystemFileModal">Update File</button></li>
              <li><button class="dropdown-item" type="button" (click)="openUpdateModel(data)">Update Info</button></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><button class="dropdown-item" type="button" (click)="removeSaleSystem(data.name)">Remove</button></li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>

<!-- Create Sale System Model -->
<div class="modal" id="createSaleSystemModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Sale System</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="createSaleSystemForm">
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
          <div class="mb-3">
            <label class="form-label">File (pdf only)</label>
            <input type="file" class="form-control" formControlName="file" accept=".pdf"
              (change)="onFileSelected($event)">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="createSaleSystem()"
          [disabled]="!createSaleSystemForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Sale System Info Model -->
<div class="modal" tabindex="-1" [ngStyle]="{ display: displayStyle }" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Sale System</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label class="form-label">Description</label>
          <input type="text" class="form-control" [(ngModel)]="updateDes">
        </div>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModel()">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateSaleSystem()" [disabled]="!updateDes">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Sale System File Model -->
<div class="modal" tabindex="-1" id="updateSaleSystemFileModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Sale System File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label class="form-label">File (pdf only)</label>
          <input type="file" class="form-control" accept=".pdf" (change)="onFileSelected($event)">
        </div>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateSaleSystemFile()" [disabled]="!selectedFile">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
