import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { TokenRefresh } from "../services/tokenRefresh_service";
@Injectable({
  providedIn: 'root'
})
export class Refrsh {

  constructor(
    private service: TokenRefresh,
    private router: Router
  ) {

  }

  refreshPage() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
