import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { InvoiceApiService } from 'src/app/services/invoice_service';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
import { DateFormatPipe } from './../../../utils/date-format.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
declare var $: any

@Component({
  selector: 'app-invoice',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent {

  invoiceList: any[] = [];
  pageSize = PageSize.INVOICE_LIST;
  page = 0;
  allTotal = 0;
  loading: any = false;
  downloadFileName: any;
  invoiceType: any = '';
  dealerName: any = ''
  searchName: any = ''
  invoiceTypeList: any[] = ['ALL', 'PAID', 'UNPAID', 'DUE_TODAY']
  startDate: any;
  endDate: any;
  startDateDDMM: any;
  endDateDDMM: any;
  isShowDate = true
  filterName: any;
  isUploading: boolean = false
  nameList: any[] = []
  isDealer: boolean = false;
  isDownloadAccess: boolean = false
  totalUnpaidBalance: any
  totalDueBalance: any
  showBalance: any = false
  currentPageTotal: any
  previousPageTotal: any
  aggregatedTotal: any
  searchID: any;
  showPageTotal: any = false
  orderBy: any = 'INVOICE_DATE';
  orderByDuDate: any[] = ['INVOICE_DATE', 'DUE_DATE'];
  orderByPaidDate: any[] = ['INVOICE_DATE', 'PAID_DATE'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: InvoiceApiService,
    private refresh: Refrsh,
    private store: StoreData,
    private dateFormat: DateFormatPipe,
    private checkAccessRoles: CheckAccessRoles) {

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.dealerName = String(routeParams.get('loginName'));

    this.isDownloadAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.DEALER, UserLoginRole.ADMIN])

    if (this.store.getIsDealer()) {
      this.initDataForDealer()
    } else {
      if (this.dealerName === 'null') {
        this.initDataAll()
      } else {
        this.initData()
      }
    }
  }

  async initDataForDealer() {
    this.isDealer = true
    this.showBalance = true
    this.dealerName = ''
    try {
      await this.getAllInvoiceList(this.page);
      await this.getTotalUnpaidBalance('null');
      await this.getTotalDueBalance('null');
      await this.getNameList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  async initDataAll() {
    this.dealerName = ''
    try {
      await this.getAllInvoiceList(this.page);
      await this.getNameList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  async initData() {
    this.invoiceType = 'ALL'
    this.isDealer = true
    this.showBalance = true
    try {
      await this.filterInvoiceList(this.page);
      await this.getTotalUnpaidBalance(this.dealerName);
      await this.getTotalDueBalance(this.dealerName);
      await this.getNameList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  async getAllInvoiceList(page: number) {
    this.page = page
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getInvoiceList(this.page, this.pageSize).subscribe({
        next: (response) => {
          this.invoiceList = response.Object.invoiceInfoLst
          this.allTotal = response.Object.allTotal;

          this.aggregatedTotal = response.Object.allPagesTotalString
          this.previousPageTotal = response.Object.previousPagesTotalString

          if (this.previousPageTotal.invoiceTotal === null) {
            this.currentPageTotal = {
              invoiceTotal: this.aggregatedTotal.invoiceTotal,
              paidTotal: this.aggregatedTotal.paidTotal,
              balanceTotal: this.aggregatedTotal.balanceTotal
            }
          } else {
            const invoiceTotal = parseFloat(this.aggregatedTotal.invoiceTotal.replace(/[^\d.]/g, '')) - parseFloat(this.previousPageTotal.invoiceTotal.replace(/[^\d.]/g, ''))
            const paidTotal = parseFloat(this.aggregatedTotal.paidTotal.replace(/[^\d.]/g, '')) - parseFloat(this.previousPageTotal.paidTotal.replace(/[^\d.]/g, ''))
            const balanceTotal = parseFloat(this.aggregatedTotal.balanceTotal.replace(/[^\d.]/g, '')) - parseFloat(this.previousPageTotal.balanceTotal.replace(/[^\d.]/g, ''))

            this.currentPageTotal = {
              invoiceTotal: invoiceTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' Ks.',
              paidTotal: paidTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' Ks.',
              balanceTotal: balanceTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' Ks.'
            }
          }

        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          this.loading = false
          resolve()
        },
      })
    });
  }

  async getNameList() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getNameList().subscribe({
        next: (response) => {
          this.nameList = response
        },
        error: (e) => {
          this.loading = false
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          this.loading = false
          resolve()
        },
      })
    })
  }

  searchList = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.nameList.filter(item => item[0].toLowerCase().startsWith(term.toLowerCase()))
      )
    );
  }

  filterInvoiceList(page: any) {

    this.page = page

    if (Array.isArray(this.searchName)) {
      this.dealerName = this.searchName[0]
    }
    if (this.startDate) {
      this.startDateDDMM = this.dateFormat.convertObjectToDDMMYYYY(this.startDate)
    }
    if (this.endDate) {
      this.endDateDDMM = this.dateFormat.convertObjectToDDMMYYYY(this.endDate)
    }
    if (this.dealerName) {
      // this.filterName = this.dealerName + '/' + this.orderBy;
      this.filterName = this.dealerName;
      if (this.invoiceType) {
        this.filterName += '/' + this.invoiceType;
        if (this.startDate) {
          this.filterName += '/' + this.startDateDDMM + '/' + this.endDateDDMM;
        }
      } else if (this.startDate) {
        this.filterName += '/' + this.startDateDDMM + '/' + this.endDateDDMM;
      }
    } else if (this.invoiceType) {
      // this.filterName = this.invoiceType + '/' + this.orderBy;
      this.filterName = this.invoiceType;
      if (this.startDate) {
        this.filterName += '/' + this.startDateDDMM + '/' + this.endDateDDMM;
      }
    }

    this.isUploading = true
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      this.service.getInvoiceListByFilter(this.dealerName, this.invoiceType, this.orderBy, this.startDateDDMM, this.endDateDDMM, this.page, this.pageSize).subscribe({
        next: (response) => {
          this.invoiceList = response.Object.invoiceInfoLst
          this.allTotal = response.Object.allTotal;

          this.aggregatedTotal = response.Object.allPagesTotalString
          this.previousPageTotal = response.Object.previousPagesTotalString

          if (this.previousPageTotal.invoiceTotal === null) {
            this.currentPageTotal = {
              invoiceTotal: this.aggregatedTotal.invoiceTotal,
              paidTotal: this.aggregatedTotal.paidTotal,
              balanceTotal: this.aggregatedTotal.balanceTotal
            }
          } else {
            const invoiceTotal = parseFloat(this.aggregatedTotal.invoiceTotal.replace(/[^\d.]/g, '')) - parseFloat(this.previousPageTotal.invoiceTotal.replace(/[^\d.]/g, ''))
            const paidTotal = parseFloat(this.aggregatedTotal.paidTotal.replace(/[^\d.]/g, '')) - parseFloat(this.previousPageTotal.paidTotal.replace(/[^\d.]/g, ''))
            const balanceTotal = parseFloat(this.aggregatedTotal.balanceTotal.replace(/[^\d.]/g, '')) - parseFloat(this.previousPageTotal.balanceTotal.replace(/[^\d.]/g, ''))

            this.currentPageTotal = {
              invoiceTotal: invoiceTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' Ks.',
              paidTotal: paidTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' Ks.',
              balanceTotal: balanceTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' Ks.'
            }
          }
        },
        error: (e) => {
          this.isUploading = false
          this.loading = false
          if (e) {
            alert(e)
            resolve()
          }
        },
        complete: () => {
          this.isUploading = false
          this.loading = false
          $('#filterModal').modal('hide');
          resolve()
        },
      })
    });
  }

  checkValid(): boolean {
    if (this.isDealer) {
      return !!this.invoiceType;
    } else {
      return !!(this.invoiceType && this.searchName);
    }
  }

  clearFilter() {
    this.filterName = ''
    this.invoiceType = ''
    this.startDate = ''
    this.endDate = ''
    this.refresh.refreshPage()
  }

  invoiceInfo(arg0: any) {
    throw new Error('Method not implemented.');
  }

  goToInvoicePDF(id: any) {
    this.router.navigate(['/nav/pdfViewerV2', id])
  }


  searchByIDList(id: string) {
    if (!id) {
      this.showPageTotal = false
      if (this.store.getIsDealer()) {
        if (this.invoiceType) {
          this.filterInvoiceList(this.page)
        } else {
          this.getAllInvoiceList(this.page)
        }
      } else {
        if (this.dealerName && this.invoiceType) {
          this.filterInvoiceList(this.page)
        } else {
          this.getAllInvoiceList(this.page)
        }
      }
    } else {
      this.showPageTotal = true
      this.invoiceList = this.invoiceList.filter((x: { referenceId: string; }) =>
        x.referenceId.trim().toLowerCase().includes(id.trim().toLowerCase())
      )
    }
  }

  searchByID() {
    this.loading = true
    this.service.getInvoiceInfo(this.searchID, this.dealerName).subscribe({
      next: (response) => {
        this.invoiceList = []
        this.previousPageTotal = ''
        this.currentPageTotal = ''
        this.allTotal = 0

        this.invoiceList.push(response.Object)
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      },
    })
  }
  clearSearchByID() {
    this.refresh.refreshPage()
  }

  downloadFile() {
    this.isUploading = true
    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }
    this.service.getDownloadFile(this.downloadFileName, this.dealerName, this.invoiceType, this.startDateDDMM, this.endDateDDMM).subscribe({
      next: (value) => {
        this.downloadFileInLocal(value)
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.isUploading = false
        $('#downloadCSVModal').modal('hide');
      },
    })
  }

  getTotalUnpaidBalance(name: any) {
    return new Promise<void>((resolve, reject) => {
      this.service.getTotalBalance(name, 'UNPAID').subscribe({
        next: (response) => {
          this.totalUnpaidBalance = response.Object
        },
        error: (e) => {
          if (e) {
            resolve()
            alert(e)
          }
        },
        complete: () => {
          resolve()
        },
      })
    })
  }

  getTotalDueBalance(name: any) {
    return new Promise<void>((resolve, reject) => {
      this.service.getTotalBalance(name, 'DUE_TODAY').subscribe({
        next: (response) => {
          this.totalDueBalance = response.Object
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {

        },
      })
    });
  }

  downloadFileInLocal(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const download = document.createElement('a');
    download.href = URL.createObjectURL(blob);
    download.setAttribute('download', this.downloadFileName);
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
    this.downloadFileName = ''
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      if (this.checkValid()) {
        this.filterInvoiceList(this.page)
      } else {
        this.getAllInvoiceList(this.page);
      }
    }
  }
}
