export enum PageSize {
  ADMIN_LIST = 50,
  DEALER_LIST = 50,
  BRAND_LIST = 50,
  SALESYSTEM_LIST = 50,
  REBATE_LIST = 50,
  CREDIT_NOTES_LIST = 50,
  QUARTER_LIST = 50,
  PURCHASING_LIST = 50,
  PRICE_LIST = 50,
  INVOICE_LIST = 100,
  PREMIER_LIST = 50,
}
