<!-- Admin Info Card View -->
<div class="card" *ngIf="!isDealer">
  <div class="card-body">

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">LoginName</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.adminLoginName" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Name</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.name" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Title</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.title" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Department</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.department" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Verify Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.adminVerifyPhone" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.phone" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Email</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="email" class="form-control" [value]="adminInfoData.email" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Admin Role</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.adminRoleSet" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Enabled</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="adminInfoData.enabled" disabled>
      </div>
    </div>

  </div>
</div>

<!-- Dealer Info Card View -->
<div class="card" *ngIf="isDealer">
  <div class="card-body">

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Login Name</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.dealerLoginName" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">UPG Dealer ID</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.sapDealerId" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Contact Person Name</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.contactPersonName" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Title</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.title" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">NRC</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.nrc" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Email</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.email" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Shop Company Name</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.shopCompanyName" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Company Register No</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.companyRegisterNo" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Contact Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.contactPhone" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Verify Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.dealerVerifyPhone" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Viber Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.viberPhone" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Brand</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control"
          [value]="dealerInfoData.brandSet ? dealerInfoData.brandSet.join(' , ' ) : ''" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Sales System</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="" disabled *ngIf="dealerInfoData.salesSystemName === 'null'">
        <input type="text" class="form-control" [value]="dealerInfoData.salesSystemName" disabled
          *ngIf="dealerInfoData.salesSystemName !== 'null'">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Address</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control"
          [value]="[dealerInfoData.addressLine1, dealerInfoData.addressLine2, dealerInfoData.addressLine3, dealerInfoData.addressLine4, dealerInfoData.addressLine5].join(' ')"
          disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">City Code</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.cityCode" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">City Name</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.cityName" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Sales District Code</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.salesDistrictCode" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Township</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.township" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Region</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.region" disabled>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-3">
        <h6 class="mb-0">Division State</h6>
      </div>
      <div class="col-sm-9 text-secondary">
        <input type="text" class="form-control" [value]="dealerInfoData.divisionState" disabled>
      </div>
    </div>

    <div class="text-center mb-3">
      <h5> Please contact admin to update your details.</h5>
    </div>
  </div>
</div>
