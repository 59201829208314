import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StoreData } from '../utils/store_data';

@Injectable({
  providedIn: 'root'
})
export class HomeApiService {

  constructor(private http: HttpClient,
    private stor:StoreData) { }

  getSMSCheck(): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.stor.getAuth(),
      }),
      withCredentials: true,
      // observe: 'response' as 'response',
    }

    return this.http.get(environment.baseUrl + 'api/user/boom/balance', httpOptions)

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
    }
    return result;
  }

}
