<div class="row" *ngIf="isCreateUpdateRemoveAccess">
  <div class="col">
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createBrandModal">
      Create
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>SrNo</th>
        <th>Name</th>
        <th>Brand ID</th>
        <th>Description</th>
        <th *ngIf="isCreateUpdateRemoveAccess"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of brandList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal
        }">
        <td>{{data.srNo}}</td>
        <td>{{data.name}}</td>
        <td>{{data.sapBrandId}}</td>
        <td>{{data.description}}</td>
        <td *ngIf="isCreateUpdateRemoveAccess">
          <div style="text-align: right">
            <button type="button" class="btn btn-primary" (click)="openUpdateModel(data)">Update</button>
            <button type="button" class="btn btn-danger ml-3" (click)="removeBrand(data.name)">
              <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="!isUploading">Remove</span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>

<!-- Create Brand Model -->
<div class="modal" id="createBrandModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Brand</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="createBrandForm">
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
          <div class="mb-3">
            <label class="form-label">Brand ID</label>
            <input type="text" class="form-control" formControlName="sapBrandId">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="createBrand()"
          [disabled]="!createBrandForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Brand Model -->
<div class="modal" tabindex="-1" [ngStyle]="{ display: displayStyle }" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Brand</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="updateBrandForm">
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
          <div class="mb-3">
            <label class="form-label">Brand ID</label>
            <input type="text" class="form-control" formControlName="sapBrandId">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModel()">Close</button>
        <button type="button" class="btn btn-primary" (click)="updateBrand()"
          [disabled]="!updateBrandForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

