<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>UPG Dealer ID</th>
        <!-- <th *ngFor="let brand of precentList[0]?.brandNameAmountStringLst">{{ brand.brandName }}</th> -->
        <th>Dealer Login Name</th>
        <th>Shop Or Company Name</th>
        <th>Brand Set</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dealer of precentList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal
        } ; index as i" (click)="goToChart(dealer.brandNameAmountLst,dealer.dealerCompanyORShop)">
        <td>{{ dealer.dealerId }}</td>
        <!-- <td *ngFor="let brand of dealer.brandNameAmountStringLst">{{ brand.amountString }}</td> -->
        <td>{{dealer.dealerLoginName}}</td>
        <td>{{dealer.dealerCompanyORShop}}</td>
        <td>{{brandNameSet[i]}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>
