<div class="row" *ngIf="isCreateUpadateRemoveAccess">
  <div class="col">
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createPriceListModal">
      Create
    </button>
  </div>
</div>

<div class="table-responsive mt-2">
  <table class="table">
    <thead>
      <tr>
        <th>SrNo</th>
        <th *ngIf="!isDealer">Name</th>
        <th>Description</th>
        <th>Effective Date</th>
        <th>File Name</th>
        <th *ngIf="isCreateUpadateRemoveAccess"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of priceList | paginate
      : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal
        }">
        <td>{{data.srNo}}</td>
        <td *ngIf="!isDealer">{{data.name}}</td>
        <td>{{data.description}}</td>
        <td>{{data.effectiveDate | dateFormat}}</td>
        <td><button type="button" class="btn btn-link" (click)="openPDF(data)">{{data.fileName}}</button></td>
        <td *ngIf="isCreateUpadateRemoveAccess">
          <div style="text-align: right">
            <button type="button" class="btn btn-primary" (click)="openUpdateModel(data)">Update</button>
            <button type="button" class="btn btn-danger ml-3" (click)="removePriceList(data.name)">
              <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              <span *ngIf="!isUploading">Remove</span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
</div>

<!-- Create Price List Model -->
<div class="modal" id="createPriceListModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Price List</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="createPriceListForm">
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
          <div class="mb-3">
            <label class="form-label">Effective Date (DD/MM/YYYY)</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="effectiveDate" placeholder="DD/MM/YYYY"
                ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                  <i class="bi bi-calendar3"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">File (pdf only)</label>
            <input type="file" class="form-control" formControlName="file" accept=".pdf"
              (change)="onFileSelected($event)">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="createPriceList()"
          [disabled]="!createPriceListForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Price List Model -->
<div class="modal" tabindex="-1" [ngStyle]="{ display: displayStyle }" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Rebate</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="updatePriceListForm">
          <div class="mb-3">
            <label class="form-label">Effective Date (DD/MM/YYYY)</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="effectiveDate" placeholder="DD/MM/YYYY"
                ngbDatepicker #dupdate="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dupdate.toggle()" type="button">
                  <i class="bi bi-calendar3"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" formControlName="description">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModel()">Close</button>
        <button type="button" class="btn btn-primary" (click)="updatePriceList()"
          [disabled]="!updatePriceListForm.valid">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
