<div class="container-fluid">
  <div class="row text-center">
    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
      <a class="gallery-item" *ngFor="let d of data" [attr.data-src]="d.srcUrl">
        <img class="img-responsive" src={{d.srcUrl}} />
      </a>
      <a class="gallery-item"
        data-src="https://images.unsplash.com/photo-1544550285-f813152fb2fd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80">
        <img class="img-responsive"
          src="https://images.unsplash.com/photo-1544550285-f813152fb2fd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
      </a>
      <a class="gallery-item"
        data-src="https://images.unsplash.com/photo-1584592740039-cddf0671f3d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80">
        <img class="img-responsive"
          src="https://images.unsplash.com/photo-1584592740039-cddf0671f3d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
      </a>
    </lightgallery>
  </div>
</div>
