import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { QuaterApiService } from 'src/app/services/quater_service';
import { CheckAccessRoles } from 'src/app/utils/check-access-roles';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { Refrsh } from 'src/app/utils/refresh';
import { StoreData } from 'src/app/utils/store_data';
declare var $: any

@Component({
  selector: 'app-quater',
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule
  ],
  templateUrl: './quater.component.html',
  styleUrls: ['./quater.component.css']
})
export class QuaterComponent {
  createQuaterForm: FormGroup<any>;
  updateQuaterForm: FormGroup<any>;
  quaterStatus: any = ['ACTIVE_PRESENT_QUARTER', 'ACTIVE_PAST_QUARTER', 'UNACTIVE_PAST']
  isCreateError: any = false;
  isUpdateError: any = false;
  errorMessage: any = '';
  displayStyle: any = 'none';
  quaterList: any[] = [];
  pageSize: any = PageSize.QUARTER_LIST;
  page = 0;
  allTotal = 0;
  loading: any = false;
  quaterName = ''
  isUploading: boolean = false
  isCreateUpdateRemoveAccess: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private refresh: Refrsh,
    private store: StoreData,
    private service: QuaterApiService,
    private dateFormat: DateFormatPipe,
    private checkAccessRoles: CheckAccessRoles) {
    this.createQuaterForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      beginTs: ['', Validators.required],
      endTs: ['', Validators.required],
      quarterStatus: ['', Validators.required]
    });
    this.updateQuaterForm = this.fb.group({
      description: ['', Validators.required],
      beginTs: ['', Validators.required],
      endTs: ['', Validators.required],
      quarterStatus: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.isCreateUpdateRemoveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.MDOFFICE_UPLOAD_ADMIN])

    this.getAllQuaterList()
  }

  getAllQuaterList() {
    this.loading = true
    this.service.getQuaterList(this.page, this.pageSize).subscribe({
      next: (response) => {
        this.quaterList = response.quarterInfoLst
        this.allTotal = response.allTotal;
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      },
    })
  }

  createQuater() {
    this.isUploading = true
    var name = this.createQuaterForm.get('name')?.value
    var description = this.createQuaterForm.get('description')?.value
    var quarterStatus = this.createQuaterForm.get('quarterStatus')?.value
    var beginDate = this.dateFormat.convertObjectToDDMMYYYY(this.createQuaterForm.get('beginTs')?.value)
    var endDate = this.dateFormat.convertObjectToDDMMYYYY(this.createQuaterForm.get('endTs')?.value)

    this.service.getCreateQuater(name, description, beginDate, endDate, quarterStatus).subscribe({
      next: (value) => {
        $('#createQuaterModal').modal('hide');
        alert('Success')
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isCreateError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isCreateError = false
        this.refresh.refreshPage()
      },
    })
  }

  openUpdateModel(data: any) {
    console.log(data);

    this.displayStyle = 'block'
    this.quaterName = data.name
    this.updateQuaterForm.patchValue({
      description: data.description,
      beginTs: this.dateFormat.convertDateToObject(data.beginDate),
      endTs: this.dateFormat.convertDateToObject(data.endDate),
      quarterStatus: data.quarterStatus,
    })
  }

  closeModel() {
    this.displayStyle = 'none'
  }

  updateQuater() {
    this.isUploading = true
    var description = this.updateQuaterForm.get('description')?.value
    var quarterStatus = this.updateQuaterForm.get('quarterStatus')?.value
    var beginDate = this.dateFormat.convertObjectToDDMMYYYY(this.updateQuaterForm.get('beginTs')?.value)
    var endDate = this.dateFormat.convertObjectToDDMMYYYY(this.updateQuaterForm.get('endTs')?.value)

    this.service.getUpdateQuater(this.quaterName, description, beginDate, endDate, quarterStatus).subscribe({
      next: (response) => {
        this.displayStyle = 'none'
      },
      error: (e) => {
        this.isUploading = false
        if (e) {
          this.isUpdateError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isUploading = false
        this.isUpdateError = false
        this.refresh.refreshPage()
      },
    })
  }

  goToPurchasingPower(name: any) {
    // this.router.navigate(['/nav/purchasingAmountInfo', name]);
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllQuaterList();
    }
  }
}
