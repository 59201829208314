import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateFileName } from '../utils/create-file-name';
import { StoreData } from '../utils/store_data';
import { TokenRefresh } from './tokenRefresh_service';

@Injectable({
  providedIn: 'root'
})
export class RebateApiService {

  constructor(
    private http: HttpClient,
    private store: StoreData,
    private service: TokenRefresh,
    private file_service: CreateFileName) { }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
  getRebateList(loginName: any, type: any, page: any, pageSize: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/listmdorebateinfo?loginName=' + loginName + '&rebateType=' + type + '&page=' + page + '&pageSize=' + pageSize, httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN/ADMIN
  getNameList(): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/dealer/registration/listdealerloginname', httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getCreateRebate(file: File, loginName: any, des: any, date: any, type: any): Observable<any> {

    //const fileName = file.name.split("_")[0] + '.jpg'
    //var fileName = this.file_service.createFileName(file.name, date)
    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/upload?loginName=' + loginName + '&description=' + des + '&effectiveDate=' + date + '&rebateType=' + type, formData, { headers, withCredentials: true })

  }

  getUploadZipFileRebate(file: any, date: string, type: any): Observable<any> {
    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/zipfileupload?effectiveDate=' + date + '&rebateType=' + type, formData, { headers, withCredentials: true })

  }

  getUploadZipFileRebateStatus() {

    const httpOptions = this.getHeaders()

    return this.http.get<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/zipfileupload/status', httpOptions)

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getUpdateRebate(name: any, des: any, date: any, type: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/update?mdoRebateName=' + name + '&description=' + des + '&effectiveDate=' + date + '&rebateType=' + type, null, httpOptions)

  }

  getUpdateRebateFile(name: any, file: any, type: any) {
    var formData = new FormData();
    formData.append("file", file, file.name);

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.post<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/updatefile?mdoRebateName=' + name + '&rebateType=' + type, formData, { headers, withCredentials: true })

  }

  //PERMISSION=SUPER_ADMIN/MDOFFICE_UPLOAD_ADMIN
  getRemoveRebate(name: any, type: any): Observable<any> {

    const httpOptions = this.getHeaders()

    return this.http.post<any>(environment.baseUrl + 'api/drapp/mdorebate/registration/remove?mdoRebateName=' + name + '&rebateType=' + type, null, httpOptions)

  }

  getDownloadFile(name: any, type: any): Observable<any> {

    const headers = {
      'Accept': 'application/json',
      'Authorization': this.store.getAuth(),
    }

    return this.http.get(environment.baseUrl + 'api/drapp/mdorebate/registration/downloadmdorebate?mdoRebateName=' + name + '&rebateType=' + type, { headers, withCredentials: true, responseType: 'blob' })

  }

  getHeaders() {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true
    }
    return result;
  }

}
