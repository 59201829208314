<div *ngIf="show">
  <div
    [ngClass]="{'col-sm-12 col-md-12 col-lg-6 mt-3': multi.length <= 4, 'col-sm-12 col-md-12 col-lg-8 mt-3': multi.length > 4 && multi.length <= 8, 'col-sm-12 col-md-12 col-lg-12 mt-3': multi.length > 8}">
    <div class="text-center">
      <h5>Record-Breaking Bonus စံချိန်ချိုးဘောနပ်(စ်)</h5>
    </div>
    <div class="text-center">
      <h6>Target Vs Actual ({{pastQuarterName }}-{{presentQuarterName}})</h6>
    </div>
    <div>
      <ngx-charts-bar-vertical-2d [scheme]="colorScheme" [results]="multi" [gradient]="gradient" [xAxis]="showXAxis"
        [yAxis]="showYAxis" [legend]="showLegend" [legendTitle]="legendTitle" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [groupPadding]="10" [rotateXAxisTicks]="true" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [showDataLabel]="showDataLabel" [xAxisTickFormatting]="xAxisTickFormatting">
      </ngx-charts-bar-vertical-2d>
    </div>
  </div>

  <div class="d-flex">
    <div class="col-sm-12 col-md-12 col-lg-8 table-responsive mt-3 rounded-3">
      <table class="table">
        <thead>
          <tr>
            <th>SrNo</th>
            <th>Brand Name</th>
            <th class="text-right w-auto">Target Amount({{pastQuarterName}})</th>
            <th class="text-right w-auto">Actual Amount({{presentQuarterName}})</th>
            <th class="text-right w-auto">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of multi ; let  i=index">
            <td>{{i+1}}</td>
            <td>{{data.name}}</td>
            <td class="text-right w-auto">{{data.pastAmount.toLocaleString()}} Ks</td>
            <td class="text-right w-auto">{{data.presentAmount.toLocaleString()}} Ks</td>
            <td class="text-right w-auto" [ngStyle]="{'color': data.percent< 100 ? 'red' : 'green'}">{{data.percent}} %
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div>
    <h5>(ဖော်ပြပါ Progress growth chart သည် လူကြီးမင်း၏ Breaking Bonus စံချိန်ချိုးဘောနပ်(စ်) ရရှိစေရန်
      ဝယ်ယူအားတိုးတက်မှုရာခိုင်နှုန်းကို ဂရပ်(ဖ်)ဖြင့် ဖော်ပြခြင်းဖြစ်သည်။ Real time data အဖြစ်ဖော်ပြခြင်း
      မျိုးမဟုတ်ပါ။)</h5>
  </div>

</div>


<div class="d-flex flex-column align-items-center justify-content-center vh-100" *ngIf="!show">
  <ng-container *ngIf="loading; else notLoading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p class="mt-3">Please wait, loading data...</p>
  </ng-container>
  <ng-template #notLoading>
    <p class="mt-3">Purchasing data not found...</p>
  </ng-template>
</div>

